import { setDefaultHeader } from './axiosClient';
import { setChatToken } from 'util/chatBot';

const authTokenLocalStorageKey = 'Authorization';
const expiresLocalStorageKey = 'expires';
const issuedLocalStorageKey = 'issued';
const clientIP = 'clientIP';
const acknowledged = 'acknowledged';
const tosVID = 'tosVID';
const localStorage = (typeof window !== 'undefined' && window.localStorage) || undefined;

const storageHelper = {
    local: {
        getAuthToken: () => localStorage?.getItem(authTokenLocalStorageKey),
        getAuthExpires: () => Number(localStorage?.getItem(expiresLocalStorageKey)),
        getAuthIssued: () => Number(localStorage?.getItem(issuedLocalStorageKey)),
        setAuthToken: (token: string, issued: string, expires: string) => {
            localStorage?.setItem(authTokenLocalStorageKey, token);
            localStorage?.setItem(expiresLocalStorageKey, expires);
            localStorage?.setItem(issuedLocalStorageKey, issued);
            setChatToken(token);
            setDefaultHeader('Authorization', `Bearer ${token}`);
        },
        removeAuthToken: () => {
            if (localStorage) {
                localStorage.removeItem(authTokenLocalStorageKey);
                localStorage.removeItem(expiresLocalStorageKey);
                localStorage.removeItem(issuedLocalStorageKey);
                localStorage.removeItem(clientIP);
                localStorage.removeItem(acknowledged);
            }
            setChatToken(``);
            setDefaultHeader('Authorization', ``);
        },
        getTosVID: () => localStorage?.getItem(tosVID),
        setTosVID: (version: string) => {
            localStorage?.setItem(tosVID, version);
        },
        getClientIP: () => localStorage?.getItem(clientIP),
        setClientIP: (ip: string) => {
            localStorage?.setItem(clientIP, ip);
        },
        getAcknowledged: () => localStorage?.getItem(acknowledged),
        setAcknowledged: (hasAcknowledged: boolean) => {
            localStorage?.setItem(acknowledged, hasAcknowledged);
        }
    }
};

export default storageHelper;
