import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const MedicineCabinetSelector = (state: RootState) => state.medicineCabinetReducer;

export const medicineCabinetPrescriptionsSelector = createSelector(MedicineCabinetSelector, (medicineCabinet) =>
    medicineCabinet.currentPerscriptions ? medicineCabinet.currentPerscriptions : []
);

export const medicineShowNewPrescriptionModalSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal
);
