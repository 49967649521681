import storageHelper from 'util/storageHelper';

export function setChatToken(token: string | null | undefined) {
    if (token === undefined || token === null) {
        token = '';
    }

    var config = {
        lex: {
            sessionAttributes: {
                localTimeZone: JSON.stringify(Intl.DateTimeFormat().resolvedOptions().timeZone),
                isLoggedIn: token === '' ? 'false' : 'true',
                jwtToken: token
            }
        }
    };
    window.lexConfig = config;
    var event = new CustomEvent('loadlexconfig', { detail: { config: config } });
    document.dispatchEvent(event);
}

export function onReceiveLexConfig() {
    document.removeEventListener('receivelexconfig', onReceiveLexConfig, false);
    setChatToken(storageHelper.local.getAuthToken());
}
