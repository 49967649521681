import { CartObjectPayload } from 'state/cart/cart.services';
import { PerscriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

export function InitOptimize() {
    // ININITALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate' - needs to go on each layout
    if (window) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'optimize.activate' });
        window.document.documentElement.classList.remove('async-hide');
    }
}

function getFormName(form: HTMLElement): String {
    let fName = 'unknown-form';
    if (form) {
        if (form.getAttribute('data-ga-form-name')) {
            fName = form.getAttribute('data-ga-form-name');
        } else {
            if (form.id) {
                fName = form.id;
            }
        }
    }
    return fName;
}

function getFormStepName(form: HTMLElement): String {
    let formStepName = 'OnePageForm';
    if (form) {
        let formSteps = form.querySelector('.workflow-steps');
        if (!formSteps) {
            let workflowWrapper = form.closest('[class*="layout-form-wrapper-content"]');
            if (workflowWrapper) {
                formSteps = workflowWrapper.querySelector('.workflow-steps');
            }
        }
        if (formSteps && formSteps.getAttribute('data-ga-form-stepname')) {
            formStepName = formSteps.getAttribute('data-ga-form-stepname');
        }
    }
    return formStepName;
}

function getTargetForm(t: HTMLElement): any {
    let form = t.closest('form');
    if (!form) {
        form = t.closest('[data-ga-form-name]');
    }
    if (form) {
        return form;
    }
}

export function TrackInputFocus(eventTarget: HTMLElement, label?: string | object) {
    if (eventTarget && window && window.dataLayer) {
        const form = getTargetForm(eventTarget);
        const formName = getFormName(form);
        const formStepName = getFormStepName(form);

        const { name = 'unknown' } = eventTarget;

        window.dataLayer.push({
            event: 'inputFocus',
            'form-field': label ? label.toString().replace(/ /g, '-') : name,
            'form-stepName': formStepName,
            'form-name': formName
        });
    }
}

export function TrackFormSuccess(formName: string) {
    if (window && window.dataLayer) {
        window.dataLayer.push({
            event: 'formSubmitSuccess',
            'form-name': formName
        });
    }
}

export function TrackError(location: string, func: string, error: object) {
    if (window && window.dataLayer) {
        window.dataLayer.push({
            event: 'Error',
            location: location,
            function: func,
            errorMessage: error.toString()
        });
    }
}

export function TrackVirtualPageView(eventType: string, flowType: string, pagePath: string) {
    if (window && window.dataLayer) {
        window.dataLayer.push({
            event: eventType,
            addType: flowType,
            vpvURL: pagePath
        });
    }
}

export function TrackCheckoutStep({
    stepName,
    step,
    cart,
    prescriptions,
    shippingCost,
    t
}: {
    stepName: 'purchase' | 'checkout' | 'add' | 'remove';
    step: string;
    cart: CartObjectPayload | undefined;
    prescriptions: PerscriptionObjectPayload[];
    shippingCost: string;
    t: any;
}) {
    if (window && window.dataLayer) {
        if (cart && cart.refillRxs && cart.refillRxs.length > 0) {
            let cartProducts: any[] = [];
            cart.refillRxs.forEach((lineItem) => {
                const currentRx = prescriptions?.find((obj) => {
                    return obj.rxNumber === lineItem.rxNumber;
                });
                if (currentRx) {
                    cartProducts.push({
                        name: currentRx.dispensedProductName,
                        price: lineItem.patientCopay ? lineItem.patientCopay : 0,
                        brand: currentRx.dispensedDrugMaker,
                        variant: `${t('pages.cart.quantity', {
                            fillQuantity: currentRx.fillQuantity
                        })} ${currentRx.dispensedProductStrength}${currentRx.dispensedProductUnitOfMeasure} ${
                            currentRx.dispensedProductDosageForm
                        }`,
                        quantity: 1,
                        metric3: lineItem.messageStatus ? 1 : 0 // Adjudicated = 1, not adjudicated = 0
                    });
                }
            });

            let eventType = '';
            switch (stepName) {
                case 'add':
                    eventType = 'EEaddToCart';
                    break;
                case 'remove':
                    eventType = 'EEremovefromcart';
                    break;
                case 'purchase':
                    eventType = 'Purchase';
                    break;
                default:
                    eventType = 'EEcheckout';
                    break;
            }
            const action =
                stepName === 'purchase'
                    ? {
                          id: cart?.orderHeader?.orderInvoiceNumber,
                          revenue: cart?.orderTotal + shippingCost,
                          tax: cart?.orderHeader?.orderTax,
                          shipping: shippingCost
                      }
                    : { step: step };

            if (cartProducts.length > 0) {
                window.dataLayer.push({
                    event: eventType,
                    ecommerce: {
                        [stepName]:
                            eventType === 'EEcheckout' || eventType === 'Purchase'
                                ? {
                                      actionField: action,
                                      products: cartProducts
                                  }
                                : {
                                      products: cartProducts
                                  }
                    }
                });
            }
        }
    }
}
