import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    accountAddAddressToProfileRoutine,
    accountFetchProfileRoutine,
    accountLoginRoutine,
    accountRegisterRoutine,
    accountUpdateProfileRoutine,
    accountFetchHealthConditionsRoutine,
    accountFetchWebProfileRoutine,
    accountUpdateSecurePasswordRoutine,
    accountGetAllCreditCardsRoutine,
    accountAddPaymentRoutine,
    accountRemoveCreditCardRoutine,
    accountUpdateCreditCardRoutine,
    accountGetPaymetricDetailsRoutine,
    accountFetchSecurityQuestionsRoutine,
    accountGetContactUsMessageRoutine,
    accountSendContactUsMessageRoutine,
    accountSubmitSecurityQuestionAnswerRoutine,
    accountResetPasswordRoutine,
    accountRegisterSecurityQuestionsRoutine,
    accountFetchOrderHistoryRoutine,
    accountFetchOrderLinesRoutine,
    accountForgotUsernameRoutine,
    accountFetchNotificationsRoutine,
    accountFetchContactUsMessagesRoutine,
    accountFetchContactUsComposeMessageRoutine,
    accountGetContactUsMessageThreadRoutine,
    accountFetchContactUsReplyMessageRoutine,
    accountPostContactUsReplyMessageRoutine,
    accountPostContactUsComposeMessageRoutine,
    accountPostContactUsCloseMessageRoutine,
    accountFetchAskThePharmMessagesRoutine,
    accountFetchAskThePharmComposeMessageRoutine,
    accountGetAskThePharmMessageThreadRoutine,
    accountFetchAskThePharmReplyMessageRoutine,
    accountPostAskThePharmReplyMessageRoutine,
    accountPostAskThePharmCloseMessageRoutine,
    accountGetAcknowledgementRoutine,
    accountUpdateAllergiesRoutine,
    accountUpdateMedicalConditionsRoutine,
    accountClearMessageThreadRoutine
} from 'state/account/account.routines';
import { produce } from 'immer';
import storageHelper from 'util/storageHelper';
import {
    PaymetricDetailsPayload,
    AccountSecurityPayload,
    APILoginPayload,
    CreditCardPayload,
    PatientRegistrationPayload,
    ProfileObjectPayload,
    WebProfilePayload,
    ContactUsPayload,
    NotificationsPayload,
    AllMessagesPayload,
    AskThePharmMessagePayload,
    ContactUsMessagePayload,
    AcknowledgementPayload,
    MessageThread
} from './account.services';
import { Allergies, HealthConditions, MedicalConditions } from 'types/health-profile';
import { SecurityQuestionsFormValues } from 'components/registration-security-questions/registration-security-questions';
import { FormikHelpers } from 'formik';
import { Order, OrderHistoryResponsePayload, OrderLinesResponsePayload } from 'types/order';

export const SESSION_DURATION = 600000;

export const defaultMedicalConditions = {
    Asthma: false,
    COPD: false,
    Cholesterol: false,
    Diabetes: false,
    Glaucoma: false,
    Heart: false,
    Hypertension: false,
    Kidney: false,
    Stroke: false,
    Thyroid: false,
    Other: '',
    TemporaryOther: '',
    ePostPatientNum: ''
};
export const defaultAllergies = {
    Tetracyclines: false,
    Penicillin: false,
    Cephalosporins: false,
    Ampicillin: false,
    Sulfa: false,
    Erythromycin: false,
    Aspirin: false,
    Codeine: false,
    NSAIDs: false,
    Quinolones: false,
    Other: '',
    TemporaryOther: '',
    ePostPatientNum: ''
};

export interface AccountState {
    token: string | undefined | null;
    issued: number | undefined;
    expires: number | undefined;
    sessionExpiration: number;
    registration: {
        hasError: boolean;
        error: string;
        patientRegistration?: PatientRegistrationPayload;
        patientRegistrationSecurityQuestions?: SecurityQuestionsFormValues;
    };
    healthConditions: HealthConditions;
    error?: string;
    isBusy: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isFetchingOrderHistory: boolean;
    isFetchingOrderLines: boolean;
    isFetchingSecurityQuestions: boolean;
    isSubmittingPasswordReset: boolean;
    isSubmittingSecurityQuestionAnswer: boolean;
    isSubmittingUsernameRequest: boolean;
    accountSecurity?: AccountSecurityPayload;
    orderHistory?: Order[];
    profileObject?: ProfileObjectPayload;
    webProfile?: WebProfilePayload;
    passwordChangeSuccess?: boolean | undefined;
    passwordChangeError?: string | undefined;
    creditCards?: CreditCardPayload[];
    paymetricDetails?: PaymetricDetailsPayload | undefined;
    pciToken?: string | undefined;
    contactUs?: ContactUsPayload;
    notifications?: NotificationsPayload;
    messages?: AllMessagesPayload;
    acknowledgement?: AcknowledgementPayload;
    currentVid: number | undefined;
    currentIP: string | undefined;
}

export const initialState: AccountState = {
    token: storageHelper.local.getAuthToken(),
    issued: storageHelper.local.getAuthIssued(),
    expires: storageHelper.local.getAuthExpires(),
    sessionExpiration: Date.now() + SESSION_DURATION,
    registration: {
        hasError: false,
        error: '',
        patientRegistration: undefined,
        patientRegistrationSecurityQuestions: undefined
    },
    error: undefined,
    isBusy: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isFetchingOrderHistory: false,
    isFetchingOrderLines: false,
    isFetchingSecurityQuestions: false,
    isSubmittingPasswordReset: false,
    isSubmittingSecurityQuestionAnswer: false,
    isSubmittingUsernameRequest: false,
    accountSecurity: undefined,
    orderHistory: undefined,
    profileObject: undefined,
    healthConditions: {
        medicalConditions: defaultMedicalConditions,
        allergies: defaultAllergies
    },
    passwordChangeSuccess: undefined,
    passwordChangeError: undefined,
    creditCards: undefined,
    notifications: undefined,
    messages: {
        filters: [],
        askThePharm: undefined,
        contactUs: undefined,
        composeMessage: {
            contactUs: undefined,
            askThePharm: undefined
        },
        replyMessage: {
            contactUs: undefined,
            askThePharm: undefined
        },
        messageThread: {
            replies: undefined,
            subject: undefined,
            messageSource: undefined,
            threadStatus: undefined
        }
    },
    currentVid: undefined,
    acknowledgement: {
        TotalCount: undefined,
        PageNo: undefined,
        PageSize: undefined,
        Acknowledgements: [
            {
                EPostPatientNum: '',
                Version: undefined,
                Type: '',
                AddedDate: '',
                Source: '',
                SourceIP: ''
            }
        ]
    }
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        logout() {
            storageHelper.local.removeAuthToken();
            if (typeof window !== undefined) {
                if (window.dataLayer) {
                    window.dataLayer.push({ event: 'logout', loginStatus: 'logged out' });
                }
                // Removed the hard page-reload, since the store is now being cleared out correctly (you can't do "store = xya")
                // window.location.reload();
            }
            return { ...initialState, isLoggingOut: true, token: null, issued: undefined, expires: undefined };
        },
        refreshSessionExpiration(state: AccountState) {
            state.sessionExpiration = Date.now() + SESSION_DURATION;
        },
        setCurrentVid(state: any, action) {
            state.currentVid = action.payload;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Account Login Reducers
         */
        addCase(accountFetchHealthConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions = {
                    medicalConditions: {
                        ...payload.medicalConditions,
                        TemporaryOther: state.healthConditions.medicalConditions.TemporaryOther
                    },
                    allergies: {
                        ...payload.allergies,
                        TemporaryOther: state.healthConditions.allergies.TemporaryOther
                    }
                };
            })
        );
        addCase(accountLoginRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoggingIn = true;
            })
        );
        addCase(accountLoginRoutine.SUCCESS, (state, { payload }: PayloadAction<APILoginPayload>) =>
            produce(state, (draftState) => {
                draftState.token = payload.access_token;
                draftState.expires = Date.parse(payload['.expires']);
                draftState.issued = Date.parse(payload['.issued']);
                draftState.isLoggingIn = false;
                draftState.isLoggingOut = false;
                draftState.sessionExpiration = Date.now() + SESSION_DURATION;

                storageHelper.local.setAuthToken(
                    payload.access_token,
                    draftState.issued.toString(),
                    draftState.expires.toString()
                );
            })
        );
        addCase(accountLoginRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            // produce(state, (draftState) => {
            //     draftState.error = payload;
            //     draftState.isLoggingIn = false;
            //     draftState.isLoggingOut = true;
            // })

            // DEMO: Simulate successful login.
            produce(state, (draftState) => {
                draftState.token = '1234';
                draftState.expires = Date.now() + 10800000; // 3 hours
                draftState.issued = Date.now();
                draftState.isLoggingIn = false;
                draftState.isLoggingOut = false;
                draftState.sessionExpiration = Date.now() + SESSION_DURATION;

                storageHelper.local.setAuthToken('1234', draftState.issued.toString(), draftState.expires.toString());
            })
        );

        /**
         * Order History
         */
        addCase(accountFetchOrderHistoryRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderHistory = true;
            })
        );
        addCase(
            accountFetchOrderHistoryRoutine.SUCCESS,
            (state, { payload }: PayloadAction<OrderHistoryResponsePayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingOrderHistory = false;
                    draftState.orderHistory = payload.orders;
                })
        );
        addCase(accountFetchOrderHistoryRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderHistory = false;
            })
        );

        /**
         * Order Lines
         */
        addCase(accountFetchOrderLinesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderLines = true;
            })
        );
        addCase(accountFetchOrderLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<OrderLinesResponsePayload>) =>
            produce(state, (draftState) => {
                if (draftState.orderHistory) {
                    draftState.isFetchingOrderLines = false;
                    let order = draftState.orderHistory.find((order) => order.epostOrderNum === payload.epostOrderNum);
                    if (order) {
                        order.orderLines = payload.orderLines;
                    }
                }
            })
        );
        addCase(accountFetchOrderLinesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderLines = false;
            })
        );

        /**
         * Profile Fetching Reducers
         */
        addCase(accountFetchProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
            })
        );
        addCase(accountFetchProfileRoutine.FAILURE, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                // TODO: Investigate payload - determine when this happens - after login?
                draftState.profileObject = undefined;
            })
        );

        /**
         * Health Conditions
         */
        addCase(accountUpdateMedicalConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<MedicalConditions>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.medicalConditions.TemporaryOther = payload.TemporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: state.healthConditions?.allergies || defaultAllergies,
                        medicalConditions: {
                            ...payload,
                            TemporaryOther: state.healthConditions?.medicalConditions.TemporaryOther
                        }
                    };
                }
            })
        );
        addCase(accountUpdateAllergiesRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.allergies.TemporaryOther = payload.TemporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: {
                            ...payload,
                            TemporaryOther: state.healthConditions?.allergies.TemporaryOther
                        },
                        medicalConditions: state.healthConditions?.medicalConditions || defaultMedicalConditions
                    };
                }
            })
        );

        /**
         * Account Register Reducers
         */
        addCase(accountRegisterRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.registration.hasError = false;
                draftState.registration.error = '';
            })
        );
        addCase(
            accountRegisterRoutine.SUCCESS,
            (
                state,
                {
                    payload: { patientRegistrationPayload, formValues }
                }: PayloadAction<{
                    patientRegistrationPayload: PatientRegistrationPayload;
                    formValues: any;
                }>
            ) =>
                produce(state, (draftState) => {
                    draftState.registration.hasError = false;
                    draftState.registration.error = '';
                    draftState.registration.patientRegistration = patientRegistrationPayload;
                })
        );
        addCase(accountRegisterRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.registration.hasError = true;
                draftState.registration.error = payload;
            })
        );
        addCase(
            accountRegisterSecurityQuestionsRoutine.TRIGGER,
            (
                state,
                {
                    payload
                }: PayloadAction<{
                    formValues: Partial<SecurityQuestionsFormValues>;
                    formHelpers: FormikHelpers<Partial<SecurityQuestionsFormValues>>;
                }>
            ) =>
                produce(state, (draftState) => {
                    draftState.registration.patientRegistrationSecurityQuestions = payload.formValues as SecurityQuestionsFormValues;
                })
        );

        /**
         * Account Update Profile Reducers
         */
        addCase(accountUpdateProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
            })
        );

        addCase(accountUpdateProfileRoutine.FAILURE, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                // TODO: Investigate payload
                draftState.profileObject = payload;
            })
        );

        /**
         * Account Add Address Reducers
         */
        addCase(accountAddAddressToProfileRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(accountAddAddressToProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
            })
        );

        /**
         * Web Profile Fetching Reducers
         */
        addCase(accountFetchWebProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<WebProfilePayload>) =>
            produce(state, (draftState) => {
                draftState.webProfile = payload;
            })
        );
        addCase(accountFetchWebProfileRoutine.FAILURE, (state, { payload }: PayloadAction<WebProfilePayload>) =>
            produce(state, (draftState) => {
                draftState.webProfile = undefined;
            })
        );

        /**
         * Secure Password Updating Reducers
         */
        addCase(accountUpdateSecurePasswordRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.passwordChangeSuccess = payload;
            })
        );
        addCase(accountUpdateSecurePasswordRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.passwordChangeError = payload;
            })
        );

        /**
         * Password Reset
         */
        addCase(accountFetchSecurityQuestionsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingSecurityQuestions = true;
            })
        );
        addCase(
            accountFetchSecurityQuestionsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AccountSecurityPayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingSecurityQuestions = false;
                    draftState.accountSecurity = payload;
                })
        );
        addCase(accountFetchSecurityQuestionsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingSecurityQuestions = false;
                draftState.accountSecurity = undefined;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = true;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = false;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = false;
            })
        );
        addCase(accountResetPasswordRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = true;
            })
        );
        addCase(accountResetPasswordRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );
        addCase(accountResetPasswordRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );

        /**
         * Forgot username
         */
        addCase(accountForgotUsernameRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = true;
            })
        );
        addCase(accountForgotUsernameRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = false;
            })
        );
        addCase(accountForgotUsernameRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = false;
            })
        );

        /**
         * Account Credit Cards Reducers
         */
        addCase(accountGetAllCreditCardsRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
            })
        );

        addCase(accountGetAllCreditCardsRoutine.FAILURE, (state, { payload }: PayloadAction<[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
            })
        );

        addCase(accountAddPaymentRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
            })
        );

        addCase(
            accountRemoveCreditCardRoutine.SUCCESS,
            (state, { payload }: PayloadAction<CreditCardPayload[] | undefined>) =>
                produce(state, (draftState) => {
                    draftState.creditCards = payload;
                })
        );

        addCase(accountUpdateCreditCardRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
            })
        );

        addCase(accountGetPaymetricDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.paymetricDetails = payload;
            })
        );

        addCase(accountGetContactUsMessageRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.contactUs = payload;
            })
        );

        addCase(accountSendContactUsMessageRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.contactUs = payload;
            })
        );

        /**
         * Account Notifications Reducers
         */
        addCase(accountFetchNotificationsRoutine.SUCCESS, (state, { payload }: PayloadAction<NotificationsPayload>) =>
            produce(state, (draftState) => {
                draftState.notifications = payload;
            })
        );

        addCase(accountFetchNotificationsRoutine.FAILURE, (state, { payload }: PayloadAction<NotificationsPayload>) =>
            produce(state, (draftState) => {
                draftState.notifications = payload;
            })
        );

        /**
         * Account Messages Reducers - Ask the Pharm
         */
        addCase(
            accountFetchAskThePharmMessagesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AskThePharmMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.askThePharm = payload;
                })
        );

        addCase(accountFetchAskThePharmMessagesRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.askThePharm = payload;
            })
        );

        addCase(
            accountFetchAskThePharmComposeMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AskThePharmMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.composeMessage.askThePharm = payload;
                })
        );

        addCase(accountGetAskThePharmMessageThreadRoutine.SUCCESS, (state, { payload }: PayloadAction<MessageThread>) =>
            produce(state, (draftState) => {
                draftState.messages.messageThread = payload;
            })
        );

        addCase(
            accountFetchAskThePharmReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AskThePharmMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.replyMessage.askThePharm = payload;
                })
        );

        addCase(
            accountPostAskThePharmReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AskThePharmMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.replyMessage.askThePharm = payload;
                })
        );

        addCase(
            accountPostAskThePharmCloseMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AskThePharmMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.replyMessage.askThePharm = payload;
                })
        );

        /**
         * Account Messages Reducers - Contact Us
         */

        addCase(
            accountFetchContactUsMessagesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.contactUs = payload;
                })
        );

        addCase(accountFetchContactUsMessagesRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.contactUs = payload;
            })
        );

        addCase(
            accountFetchContactUsComposeMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.composeMessage.contactUs = payload;
                })
        );

        addCase(
            accountPostContactUsComposeMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.composeMessage.contactUs = payload;
                })
        );

        addCase(
            accountPostContactUsCloseMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.composeMessage.contactUs = payload;
                })
        );

        addCase(accountGetContactUsMessageThreadRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.messageThread = payload;
            })
        );

        addCase(accountClearMessageThreadRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                console.log(payload);
                draftState.messages.messageThread = payload;
            })
        );

        addCase(
            accountFetchContactUsReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.replyMessage.contactUs = payload;
                })
        );

        addCase(
            accountPostContactUsReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.replyMessage.contactUs = payload;
                })
        );

        /**
         * Account acknowledgement Reducers
         */
        addCase(accountGetAcknowledgementRoutine.SUCCESS, (state, { payload }: PayloadAction<AcknowledgementPayload>) =>
            produce(state, (draftState) => {
                draftState.acknowledgement = payload;
            })
        );
        addCase(accountGetAcknowledgementRoutine.FAILURE, (state, { payload }: PayloadAction<AcknowledgementPayload>) =>
            produce(state, (draftState) => {
                draftState.acknowledgement = payload;
            })
        );
    }
});

export const { logout, refreshSessionExpiration, setCurrentVid } = accountSlice.actions;

export default accountSlice.reducer;
