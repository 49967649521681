import 'styles/birdi-theme.scss';

import { onReceiveLexConfig } from 'util/chatBot';

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

export const onInitialClientRender = () => {
    document.addEventListener('receivelexconfig', onReceiveLexConfig, false);
};

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};

export const onRouteUpdate = () => {
    if (window.dataLayer) {
        window.dataLayer.push({ event: 'pageview' });
    }
    if (window.document) {
        window.document.body.classList.remove('scroll-lock');
    }
};
