import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const CartSelector = (state: RootState) => state.cartReducer;

export const cartSelector = createSelector(CartSelector, (cart) => cart.cart);
export const cartItemsSelector = createSelector(CartSelector, (state) => state.cart?.refillRxs);
export const cartItemsTotalSelector = createSelector(CartSelector, (state) => state.cart?.refillRxs?.length);
export const cartOrderBillShipSelector = createSelector(CartSelector, (state) => state.cart?.orderBillShip);
export const cartOrderBillShipMethodSelector = createSelector(
    CartSelector,
    (state) => state.cart?.orderBillShip?.shipMethodId
);
