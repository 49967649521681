import { call } from 'redux-saga/effects';

import { BaseEffectHandler } from 'types/saga-handler';

export function* baseEffectHandler<P>({
    service,
    data,
    isAuthenticatedService,
    isLoggedIn,
    onResponse,
    onError
}: BaseEffectHandler<P>): any {
    try {
        if (isAuthenticatedService && !isLoggedIn) {
            throw new Error('Authenticated service requires login to call.');
        }

        const response = yield call(service, data);
        if (!response.error) {
            if (onResponse) yield onResponse(response);
        } else {
            console.error('Error Response', response);
            throw response.error;
        }
    } catch (err) {
        console.error('err => ', err);
        if (onError) yield onError(err);
    }
}

const defaultExport = { baseEffectHandler };
export default defaultExport;
