import axios from 'axios';
import axiosClient from 'util/axiosClient';
import { RegistrationFormValues } from 'components/registration-form/registration-form.component';
import { monthOptions } from 'const/options';

import { BaseService } from 'types/service';
import { Allergies, MedicalConditions } from 'types/health-profile';
import { ForgotUsernameRequestPayload } from 'types/forgot-username';
import { Login } from 'types/login';
import {
    OrderHistoryRequestPayload,
    OrderHistoryResponsePayload,
    OrderLinesRequestPayload,
    OrderLinesResponsePayload
} from 'types/order';
import { ProfileFormValues } from 'types/profile';
// TODO (TECH DEBT): components/registration-form contains this interface now, should be moved back to types/registration
import { Registration } from 'types/registration';

export interface AccountSecurityPayload {
    ePostPatientNum?: string;
    email?: string;
    longToken?: string;
    newPassword?: string;
    passwordAnswer1?: string;
    passwordAnswer2?: string;
    passwordAnswer3?: string;
    passwordQuestion1?: string;
    passwordQuestion2?: string;
    passwordQuestion3?: string;
    shortToken?: string;
    uid?: string;
    username?: string;
}

export interface ProfileObjectPhonePayload {
    epostPatientNum: string;
    phoneAreaCode: string;
    phoneExtension: string;
    phoneNumber: string;
    phoneSeqNum: string;
    phoneTypeDesc: string;
    phoneTypeNum: string;
    primaryPhone: boolean;
    uiPhone: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

export interface ProfileObjectAddressPayload {
    active: boolean;
    address1: string;
    address2: string;
    address3: string;
    addressNote: string;
    addressSeqNum: string;
    addressTypeDesc: string;
    addressTypeNum: string;
    city: string;
    country: string;
    defaultBill: boolean;
    defaultPhoneNumber: string;
    defaultPhoneSeq: string;
    defaultShip: boolean;
    endDate: string;
    epostPatientNum: string;
    startDate: string;
    state: string;
    stateNum: string | null;
    zipcode: string;
    zipcodeFour: string;
    currentShipping: boolean;
    messageErrorText: string | null;
    messageStatus: boolean | null;
    messageText: string | null;
    onSuccess?: Function;
    onFailure?: Function;
}

export interface ProfileObjectPayload {
    autoContactDesc: string;
    autoContactTypeId: string;
    autoRefill: boolean;
    epostMemberId: string;
    epostPatientNum: string;
    externalLink: string;
    familyId: string;
    genderCode: string;
    generalStatusCode: string;
    genericsAvailable: boolean;
    languageDesc: string;
    languageId: string;
    patientBalance: string;
    patientCreditLimit: string;
    patientDob: string;
    patientEmailAddress: string;
    patientFirstName: string;
    patientId: string;
    patientIdCode: string;
    patientLastName: string;
    patientMaidenName: string;
    patientMiddleName: string;
    preferredPaymentMethodDesc: string;
    preferredPaymentMethodId: string;
    preferredShipMethodDesc: string;
    preferredShipMethodId: string;
    safetyCap: boolean;
    titleDesc: string;
    titleId: string;
    cardholderID: string;
    insuranceName: string;
    payorName: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    addresses: ProfileObjectAddressPayload[];
    phones: ProfileObjectPhonePayload[];
    onSuccess?: () => void;
    onFailure?: () => void;
}

export interface WebProfilePayload {
    ePostPatientNum: string;
    ePostFamilyId: string;
    ResetPassword: Boolean;
    PasswordQuestion1: string;
    PasswordAnswer1: string;
    PasswordQuestion2: string;
    PasswordAnswer2: string;
    PasswordQuestion3: string;
    PasswordAnswer3: string;
    TimeStamp: Date;
    Email: string;
    EmailConfirmed: Boolean;
    PasswordHash: string;
    SecurityStamp: string;
    PhoneNumber: string;
    PhoneNumberConfirmed: Boolean;
    TwoFactorEnabled: Boolean;
    LockoutEndDateUtc: Date;
    LockoutEnabled: Boolean;
    AccessFailedCount: 0;
    Roles: Array<{}>;
    Claims: Array<{}>;
    Logins: Array<{}>;
    Id: string;
    UserName: string;
}

export interface APILoginPayload {
    '.expires': string;
    '.issued': string;
    access_token: string;
    expires_in: number;
    sys_user_num: string;
    token_type: string;
    userName: string;
}

export interface ProfileAddPaymentObjectPayload {
    readonly cardNumber: string;
    readonly nameOnCard: string;
    readonly expMonth: string;
    readonly expYear: string;
    readonly cvcNumber: string;
}

export interface APIHealthConditionsPayload {
    Ampicillin: boolean;
    None: boolean;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}
export interface APIMedicalConditionsPayload extends APIHealthConditionsPayload, MedicalConditions {}

export interface APIAllergiesPayload extends APIHealthConditionsPayload, Allergies {}

export interface CreditCardPayload {
    FSACard: Boolean;
    cardActive: Boolean;
    cardFirstName: string;
    cardLastName: string;
    cardMiddleName: string;
    defaultCard: Boolean;
    epostPatientNum: string;
    forceApproval: Boolean;
    cardMonthNum: string;
    cardMonthDesc: string;
    cardNumber: string;
    cardSeqNum: string;
    secureCardNumber: string;
    cardTypeNum: string;
    cardType: string;
    cardYear: string;
    pciToken: string;
    securityCode: string;
    cardExpiration: string;
    cardName: string;
    spendingLimit: string;
    cardIsExpired: Boolean;
    messageErrorText: string;
    messageStatus: Boolean;
    messageText: string;
    onSuccess?: Function;
    onFailure?: Function;
}

export interface AllCreditCardsPayload {
    allPayments: Array<CreditCardPayload>;
}

export interface PaymetricDetailsPayload {
    MerchantId: string;
    ServiceUrl: string;
    AccessToken: string;
    ScriptUrl: string;
}

export interface TokenizePaymentCardPayload {
    onSuccess: Function;
    onFailure: Function;
}

export interface ContactUsPayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: 0;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    WebUserID: string;
    EmailAddress: string;
    MessageType: number;
    FirstName: string;
    LastName: string;
    Phone: string;
    ContactMethod: string;
    onSuccess: Function;
    onFailure: Function;
    loggedIn?: Boolean;
}

export interface NotificationsPayload {
    EPostPatientNum: string;
    NewScriptID: Number;
    NewScriptLastNotify: string;
    NewScriptEmail: Boolean;
    NewScriptEmailAddress: string;
    NewScriptPhone: Boolean;
    NewScriptPhoneNumber: string;
    NewScriptText: Boolean;
    NewScriptTextNumber: string;
    RefillReminderID: Number;
    RefillReminderLastNotify: string;
    RefillReminderEmail: Boolean;
    RefillReminderEmailAddress: string;
    RefillReminderPhone: Boolean;
    RefillReminderPhoneNumber: string;
    RefillReminderText: Boolean;
    RefillReminderTextNumber: string;
    OrderShippedID: Number;
    OrderShippedLastNotify: string;
    OrderShippedEmail: Boolean;
    OrderShippedEmailAddress: string;
    OrderShippedPhone: Boolean;
    OrderShippedPhoneNumber: string;
    OrderShippedText: Boolean;
    OrderShippedTextNumber: string;
    ConsentAutoCalls: Boolean;
    IsPCM: Boolean;
    onSuccess?: Function;
    onFailure?: Function;
}

export interface AllMessagesPayload {
    filters: string[];
    askThePharm: AskThePharmMessagePayload[];
    contactUs: ContactUsMessagePayload[];
    composeMessage: ComposeMessagePayload;
    replyMessage: ComposeMessagePayload;
    messageThread: MessageThread;
}

export interface ComposeMessagePayload {
    contactUs: ContactUsComposeMessagePayload;
    askThePharm: AskThePharmComposeMessagePayload;
}

export interface AskThePharmMessagePayload {
    HeaderID: number;
    Subject: string;
    Date: Date;
    Action: string;
    PharmReply: Boolean;
    LastReponseBy: string;
}

export interface MessageThread {
    replies: object[];
    subject: string;
    messageSource: string;
    threadStatus: string;
}

export interface AskThePharmComposeMessagePayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: number;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    AccountID: string;
    WebUserID: string;
    PatientDOB: Date;
    Email: string;
    onSuccess?: Function;
    onFailure?: Function;
}

export interface ContactUsMessagePayload {
    HeaderID: number;
    Subject: string;
    Date: Date;
    Action: string;
    MessageType: number;
    UIDate: string;
    Author: string;
}

export interface ContactUsComposeMessagePayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: number;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    WebUserID: string;
    EmailAddress: string;
    MessageType: number;
    FirstName: string;
    LastName: string;
    Phone: string;
    ContactMethod: string;
    onSuccess?: Function;
    onFailure?: Function;
}

export interface PatientRegistrationPayload {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    BirthDate: string;
    GenderCode: string;
    epostPatientNum: number;
    FamilyId: number;
    AccountId: number;
    PatientStatusCode: string;
    PatientStatus: string;
    CardNumber: string;
    CoverageTypeId: number;
    CoverageType: string;
    RelationshipNumber: number;
    Relationship: string;
    Payor: string;
    PayorNum: number;
    PlanAlias: string;
    GroupId: string;
    GroupName: string;
    EmailAddress: string;
    PhoneSeqNum: number;
    PhoneTypeNum: number;
    PhoneType: string;
    AreaCode: string;
    PhoneNumber: string;
    Ext: string;
    AddressSeqNumb: number;
    AddressTypeNum: number;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    StateCode: string;
    StateNumber: number;
    ZipCode: string;
    Zip4: number;
    Country: string;
    CountryNumber: number;
    RecordLocation: string;
    HasUserProfile: number;
    UserName: string;
    WebId: string;
    IsMID: number;
    CoverageStartDate: string;
    CoverageEndDate: string;
    ReadHIPAA: boolean;
    AcceptedHIPAA: boolean;
    CacheId: string;
    RegCode: number;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

export interface AccountSetupPayload {
    Id: string;
    Email: string;
    PhoneNumber: string;
    UserName: string;
    Password: string;
    PasswordQuestion1: string;
    PasswordAnswer1: string;
    PasswordQuestion2: string;
    PasswordAnswer2: string;
    PasswordQuestion3: string;
    PasswordAnswer3: string;
    CacheId: string;
    SecurityQuestions: {};
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

export interface AcknowledgementPayload {
    TotalCount: number | undefined;
    PageNo: number | undefined;
    PageSize: number | undefined;
    Acknowledgements: [
        {
            EPostPatientNum: string;
            Version: number | undefined;
            Type: string;
            AddedDate: string;
            Source: string;
            SourceIP: string;
        }
    ];
}

/**
 * Anything related to Authentication in the system.
 */
export default class AccountService {
    static login() {
        return {
            post: (payload?: Login): Promise<any> => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/Token`, payload)
                    .then((response) => response.data)
                    .catch((err) => err.response);
            }
        };
    }
    static register(): BaseService<RegistrationFormValues> {
        return {
            get: (payload?: RegistrationFormValues): Promise<PatientRegistrationPayload> => {
                const monthNumber = monthOptions.findIndex((month) => month.value === payload?.dobMonth);
                const dobDateTime = new Date(Number(payload?.dobYear), Number(monthNumber), Number(payload?.dobDay));
                const dobString =
                    ('0' + (dobDateTime.getMonth() + 1)).slice(-2) +
                    '-' +
                    ('0' + dobDateTime.getDate()).slice(-2) +
                    '-' +
                    dobDateTime.getFullYear();
                return axiosClient
                    .get(
                        `${process.env.GATSBY_API_BASE_URL}/api/Registration/Validate/${payload?.firstName}/${payload?.lastName}/${dobString}/${payload?.memberId}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static accountSetup(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${process.env.GATSBY_API_BASE_URL}/api/Registration/AccountSetup`, payload);
            }
        };
    }
    static accountFinalize(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${process.env.GATSBY_API_BASE_URL}/api/Registration/Finalize`, payload);
            }
        };
    }
    static acceptTos(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${process.env.GATSBY_API_BASE_URL}/api/Registration/AccountSetup`, payload);
            }
        };
    }
    static fetchProfile(): BaseService<Registration> {
        return {
            get: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Patient/Profile`)
                    .then((response) => response.data);
            },
            post: (payload: ProfileObjectPayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/Patient/UpdatePatient`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static orderHistory(): BaseService<OrderHistoryResponsePayload> {
        return {
            get: (payload: OrderHistoryRequestPayload): Promise<any> => {
                return axiosClient
                    .get(
                        `${process.env.GATSBY_API_BASE_URL}/api/Order/PatientOrders/${payload.from}/${payload.to}/${payload.page}/${payload.pageSize}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static orderLines(): BaseService<OrderLinesResponsePayload> {
        return {
            get: (payload: OrderLinesRequestPayload): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Order/OrderLines/${payload.epostOrderNum}`)
                    .then((response) => response.data);
            }
        };
    }
    static medicalConditions() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/MedicalConditions`)
                    .then((response) => response.data);
            },
            post: (payload?: MedicalConditions): Promise<any> => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/MedicalConditions/Update`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static allergies() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Allergies`)
                    .then((response) => response.data);
            },
            post: (payload?: Allergies): Promise<any> => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/Allergies/Update`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static paymentMethods() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/CreditCard/ActiveCards`)
                    .then((response) => response.data);
            },
            getAccessID: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Payments/PaymetricCredentials`)
                    .then((response) => response.data);
            },
            getTokenizedCardNumber: (payload?: string): Promise<any> => {
                return axiosClient
                    .get(
                        `${process.env.GATSBY_API_BASE_URL}/api/Payments/GetTokenizedCardNumber?accessToken=${payload}`
                    )
                    .then((response) => response.data);
            },
            post: (payload?: CreditCardPayload): Promise<any> => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/CreditCard/UpdateCard`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static fetchWebProfile() {
        return {
            get: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Patient/WebProfile`)
                    .then((response) => response.data);
            }
        };
    }
    static fetchSecurityQuestions() {
        return {
            get: (username: string | undefined): Promise<any> => {
                return axiosClient
                    .get(
                        encodeURI(
                            `${process.env.GATSBY_API_BASE_URL}/api/Patient/GetSecurityInfoByUsername/?username=${username}`
                        )
                    )
                    .then((response) => response.data);
            }
        };
    }
    static submitSecurityQuestionAnswer() {
        return {
            post: (payload?: AccountSecurityPayload) => {
                return axiosClient
                    .post(
                        `${process.env.GATSBY_API_BASE_URL}/api/Patient/ValidateSecurityInfoAndGeneratePasswordToken`,
                        payload
                    )
                    .then((response) => response.data);
            }
        };
    }
    static changePassword() {
        return {
            update: (payload?: { username: string; currentPassword: string; newPassword: string }): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Patient/ChangePassword`, { params: payload })
                    .then((response) => response);
            }
        };
    }
    static contactUs() {
        return {
            get: (payload: Boolean): Promise<ContactUsPayload> => {
                return axiosClient
                    .get(
                        `${process.env.GATSBY_API_BASE_URL}${
                            payload ? '/api/ContactUs' : '/api/ContactUs/AnonymousMessage'
                        }`
                    )
                    .then((response) => response.data);
            },
            post: (payload?: ContactUsPayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${process.env.GATSBY_API_BASE_URL}${
                            payload?.loggedIn ? '/api/ContactUs/SendMessage' : '/api/ContactUs/SendExternalMessage'
                        }`,
                        payload
                    )
                    .then((response) => response.data);
            }
        };
    }
    static resetPasswordWithToken() {
        return {
            post: (payload?: AccountSecurityPayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/Patient/ResetPasswordWithToken`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static forgotUsername() {
        return {
            get: (payload: ForgotUsernameRequestPayload) => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/accounts/usernamebyemail?email=${payload?.email}`)
                    .then((response) => response.data);
            }
        };
    }
    static notifications() {
        return {
            get: () => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Notification`)
                    .then((response) => response.data);
            },
            post: (payload: NotificationsPayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/Notification`, payload)
                    .then((response) => response.data);
            }
        };
    }

    static askThePharm() {
        return {
            getAll: () => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/PatientMessageHeaders`)
                    .then((response) => response.data);
            },
            getCompose: () => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/CreateMessage`)
                    .then((response) => response.data);
            },
            postMessage: (payload: AskThePharmMessagePayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/SaveMessage`, payload)
                    .then((response) => response.data);
            },
            getMessageThread: (message: AskThePharmMessagePayload) => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/GetHeaderMessages/${message.HeaderID}`)
                    .then((response) => response.data);
            },
            getReply: (payload: any) => {
                return axiosClient
                    .get(
                        `${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/CreateReply/${payload.HeaderID}/${payload.messageAction}`
                    )
                    .then((response) => response.data);
            },
            postReply: (payload: AskThePharmMessagePayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/SaveReply`, payload)
                    .then((response) => response.data);
            },
            closeMessage: (payload: any) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/AskThePharm/CloseMessage/${payload.HeaderID}`)
                    .then((response) => response.data);
            }
        };
    }
    static contactUsMessages() {
        return {
            getAll: () => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/MessageHeaders`)
                    .then((response) => response.data);
            },
            getCompose: () => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/CreateMessage`)
                    .then((response) => response.data);
            },
            postMessage: (payload: ContactUsComposeMessagePayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/SaveMessage`, payload)
                    .then((response) => response.data);
            },
            getMessageThread: (payload: any) => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/MessagesByHeaderId/${payload.HeaderID}`)
                    .then((response) => response.data);
            },
            getReply: (payload: any) => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/CreateReply/${payload.HeaderID}`)
                    .then((response) => response.data);
            },
            postReply: (payload: ContactUsComposeMessagePayload) => {
                return axiosClient
                    .post(`${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/SaveReply`, payload)
                    .then((response) => response.data);
            },
            closeMessage: (payload: any) => {
                return axiosClient
                    .delete(
                        `${process.env.GATSBY_API_BASE_URL}/api/v2/ContactUs/CloseMessage?HeaderId=${payload.HeaderID}`
                    )
                    .then((response) => response.data);
            }
        };
    }

    static acknowledgement() {
        // Acknowledgement GETs
        const getTermsOfServiceAcknowledgement = (payload: AcknowledgementPayload) => {
            return axiosClient
                .get(`${process.env.GATSBY_API_BASE_URL}/api/patient/${payload}/acknowledgement`)
                .then((response) => {
                    return response.data;
                });
        };
        const getHipaaAcknowledgement = (payload: any) => {
            return axiosClient
                .get(`${process.env.GATSBY_API_BASE_URL}/api/patient/${payload}/hipaaStatus`)
                .then((response) => {
                    return response.data;
                });
        };

        // Acknowledgement POSTs
        const postTermsOfServiceAcknowledgement = (payload: any) => {
            return axiosClient
                .post(
                    `${process.env.GATSBY_API_BASE_URL}/api/patient/${payload.patientId}/acknowledgement`,
                    payload.termsOfServiceAcknowledgementObject
                )
                .then((response) => response.data);
        };
        const postHipaaAcknowledgement = (payload: any) => {
            return axiosClient
                .post(`${process.env.GATSBY_API_BASE_URL}/api/patient/${payload.patientId}/hipaaAgree/true`)
                .then((response) => response.data);
        };

        return {
            getAll: (payload: AcknowledgementPayload) => {
                return axios
                    .all([getTermsOfServiceAcknowledgement(payload), getHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            },
            postAll: (payload: any) => {
                return axios
                    .all([postTermsOfServiceAcknowledgement(payload), postHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            },
            postHipaa: (payload: any) => {
                return axios
                    .all([postHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            }
        };
    }
}
