import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartObjectPayload } from './cart.services';
import { produce } from 'immer';
import {
    getCartRoutine,
    cartUpdateCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine,
    cancelOrderLine,
    cartUpdateShippingRoutine,
    cartUpdatePaymentRoutine
} from './cart.routines';

export interface cartState {
    error?: string;
    isBusy?: boolean;
    cart?: CartObjectPayload;
}

export const initialState: cartState = {
    cart: undefined
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart(state: cartState, action) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = action.payload;
        },
        setOrderExpeditedShipping(state: cartState, action) {
            if (state.cart) {
                state.cart.orderHeader.orderHighPriority = action.payload.expeditedShipping;
                state.cart.orderBillShip.shipMethodId = action.payload.code;
            }
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Web Profile Fetching Reducers
         */
        addCase(getCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(getCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Start Cart Reducers
         */
        addCase(startCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(startCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Cart Reducers
         */
        addCase(cartUpdateCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdateCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Refill Lines Reducers
         */
        addCase(updateRefillLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(updateRefillLinesRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Order Line Reducers
         */
        addCase(cancelOrderLine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cancelOrderLine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Shipping Reducers
         */
        addCase(cartUpdateShippingRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdateShippingRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Payment Reducers
         */
        addCase(cartUpdatePaymentRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cartUpdatePaymentRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );
    }
});

export const { setCart, setOrderExpeditedShipping } = cartSlice.actions;

export default cartSlice.reducer;
