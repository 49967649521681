import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

export interface ModalCTAButton {
    label: string;
    variant: 'primary' | 'secondary' | 'ghost' | undefined;
    onClick: () => void;
    className?: string;
}

export interface OpenModalPayload {
    className?: string;
    contentClassName?: string;
    bodyContent: JSX.Element;
    ctas: ModalCTAButton[];
    showClose: boolean;
    onClose?: () => void;
}

export interface BirdiModalState {
    isOpen: boolean;
    bodyContent?: JSX.Element;
    className?: string;
    contentClassName?: string;
    ctas: ModalCTAButton[];
    showClose: boolean;
    onClose?: () => void;
}

const initialState: BirdiModalState = {
    isOpen: false,
    className: undefined,
    contentClassName: undefined,
    bodyContent: undefined,
    ctas: [],
    showClose: true,
    onClose: undefined
};
const birdiModalSlice = createSlice({
    name: 'birdi-modal',
    initialState,
    reducers: {
        openModal: (state, { payload }: PayloadAction<OpenModalPayload>) =>
            produce(state, (draftState) => {
                draftState.isOpen = true;
                draftState.onClose = payload.onClose;
                draftState.bodyContent = payload.bodyContent;
                draftState.className = payload.className;
                draftState.contentClassName = payload.contentClassName;
                draftState.ctas = payload.ctas;
                draftState.showClose = payload.showClose;
            }),
        closeModal: (state, _) =>
            produce(state, (draftState) => {
                draftState.isOpen = false;
            })
    }
});

export const { openModal, closeModal } = birdiModalSlice.actions;
export default birdiModalSlice.reducer;
