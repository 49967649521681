import axios from 'axios';

const builder = require('xmlbuilder');
const xml2js = require('xml2js');
const qs = require('qs');

export interface USPSVerifyAddressPayload {
    Revision: number;
    Address: USPSAddress;
}
export interface USPSAddress {
    Address1?: string;
    Address2: string;
    City: string;
    State: string;
    Zip5: string;
    Zip4: string;
}
export interface USPSApiError {
    Description: Array<string>;
}
export interface USPSVerifiedAddress extends USPSAddress {
    Error: USPSApiError;
    DeliveryPoint?: number;
    CarrierRoute?: number;
    Footnotes?: string;
    DPVConfirmation?: string;
    DPVCMRA?: string;
    DPVFootnotes?: string;
    Business?: string;
    CentralDeliveryPoint?: string;
    Vacant?: boolean;
}
export interface USPSOptions {
    addressVerifyApiUrl: string;
    uspsUsername: string;
}
export interface AddressValidateResponse {
    Address?: USPSVerifiedAddress | Array<USPSVerifiedAddress>;
}
export interface USPSAddressValidateResponse {
    AddressValidateResponse?: AddressValidateResponse;
    error?: string;
}
class USPS {
    public addressVerifyApiUrl: string;
    private username: string;
    constructor(props: USPSOptions) {
        const { addressVerifyApiUrl, uspsUsername } = props;

        this.addressVerifyApiUrl = addressVerifyApiUrl;
        this.username = uspsUsername;
    }
    public async verify(address: USPSAddress): Promise<USPSAddressValidateResponse> {
        try {
            const payload: USPSVerifyAddressPayload = {
                Revision: 1,
                Address: address
            };
            const xml = builder
                .create({
                    AddressValidateRequest: {
                        ...payload,
                        '@USERID': this.username
                    }
                })
                .end();
            const params = {
                API: 'Verify',
                XML: xml
            };

            const response = await axios.post(this.addressVerifyApiUrl, qs.stringify(params));

            //  parse the response
            const parser = new xml2js.Parser({ explicitArray: false });
            return await parser.parseStringPromise(response.data);
        } catch (err) {
            return {
                error: err
            };
        }
    }
}

export default USPS;
