import axiosClient from 'util/axiosClient';

export interface AddPrescriptionPayload {
    RequestType: string;
    DrugName: string;
    DrugForm: string;
    Strength: string;
    Qty: string;
    PrescriberName: string;
    PrescriberAddress: string;
    PrescriberCity: string;
    PrescriberState: string;
    PrescriberZip: string;
    PrescriberPhone: string;
    PrescriberFax: string;
}

export interface TransferPrescriptionPayload extends AddPrescriptionPayload {
    PharmacyName: string;
    PharmacyAddress: string;
    PharmacyCity: string;
    PharmacyState: string;
    PharmacyZip: string;
    PharmacyPhone: string;
    RxNumber: string;
    onSucces?: Function;
    onFailure?: Function;
}

/**
 * Anything related to Medicine Cabinet in the system.
 */
export default class AddTransferPrescriptionService {
    static transferRx() {
        return {
            post: (payload: any): Promise<any> => {
                return axiosClient
                    .post(
                        `${process.env.GATSBY_API_BASE_URL}/api/prescriptions/patient/${payload.patientId}/transfer`,
                        payload.request
                    )
                    .then((response) => response.data);
            }
        };
    }
    static photoUpload() {
        return {
            post: (payload?: any): Promise<any> => {
                return axiosClient.post(`${process.env.ROBIN_IMAGE_URL}/robin-uploadfile`, payload).then((response) => {
                    return response;
                });
            }
        };
    }
    static getFileFetch() {
        return {
            get: (payload?: any) => {
                return fetch(`${process.env.ROBIN_IMAGE_URL}/robin-read-file?key=${payload}`, {
                    method: 'GET',
                    redirect: 'follow'
                })
                    .then((response) => response.json())
                    .then((myJson) => myJson)
                    .catch((error) => console.error('error', error));
            }
        };
    }
}
