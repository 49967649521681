import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { VerifyAddressRequest } from 'state/usps/usps.reducers';
import { verifyAddressRoutine } from 'state/usps/usps.routines';
import USPSService from 'state/usps/usps.services';

import { baseEffectHandler } from 'util/sagas/sagas';
import { USPSAddressValidateResponse, USPSVerifiedAddress } from 'util/usps';

export default function* uspsSaga() {
    yield takeLatest(verifyAddressRoutine.TRIGGER, (action: PayloadAction<VerifyAddressRequest>) =>
        baseEffectHandler<VerifyAddressRequest>({
            service: USPSService.verify().post,
            data: action.payload,
            *onResponse(response: USPSAddressValidateResponse) {
                const { onSuccess, onFailure } = action.payload;
                const { AddressValidateResponse } = response;

                if (
                    AddressValidateResponse &&
                    AddressValidateResponse.Address &&
                    !Array.isArray(AddressValidateResponse.Address) &&
                    AddressValidateResponse.Address.Error
                ) {
                    const { Description } = AddressValidateResponse.Address.Error;
                    yield put(verifyAddressRoutine.failure({ error: Description }));
                    if (onFailure) onFailure();
                } else if (AddressValidateResponse && AddressValidateResponse.Address) {
                    const verifiedAddress: USPSVerifiedAddress = Array.isArray(AddressValidateResponse.Address)
                        ? AddressValidateResponse.Address[0]
                        : AddressValidateResponse.Address;

                    yield put(verifyAddressRoutine.success(verifiedAddress));
                    if (onSuccess) onSuccess();
                } else {
                    yield put(verifyAddressRoutine.failure({ error: 'Unspecified API Error.' }));
                    if (onFailure) onFailure();
                }
            }
        })
    );
}
