import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { AccountState } from 'state/account/account.reducers';

export const accountStateSelector = (state: RootState): AccountState => state.accountReducer;

export const accountRegistationSelector = createSelector(accountStateSelector, (account) => account.registration);
export const accountProfileSelector = createSelector(accountStateSelector, (account) => account.profileObject);
export const accountProfileAddressesSelector = createSelector(accountProfileSelector, (profile) =>
    profile && profile.addresses ? profile?.addresses : []
);
export const accountIsLoggedInSelector = createSelector(accountStateSelector, (account) => {
    const now = Date.now();
    return !account.isLoggingOut &&
        account.token &&
        account.issued &&
        account.expires &&
        now < account.expires &&
        now < account.sessionExpiration
        ? true
        : false;
});

export const accountHealthConditionsSelector = createSelector(
    accountStateSelector,
    ({ healthConditions }: AccountState) => ({
        healthConditions
    })
);

export const accountAllergiesSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.allergies
);

export const accountMedicalConditionsSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.medicalConditions
);

export const accountWebProfileSelector = createSelector(accountStateSelector, (account) => account.webProfile);
export const accountChangedPasswordSelector = createSelector(
    accountStateSelector,
    (account) => account.passwordChangeSuccess
);
export const accountCreditCardsSelector = createSelector(accountStateSelector, (account) => account.creditCards);
export const paymetricDetailsSelector = createSelector(accountStateSelector, (account) => account.paymetricDetails);
export const paymetricTokenizedCardSelector = createSelector(accountStateSelector, (account) => account.pciToken);
export const accountContactUsSelector = createSelector(accountStateSelector, (account) => account.contactUs);
export const accountNotificationsSelector = createSelector(accountStateSelector, (account) => account.notifications);
export const accountMessagesSelector = createSelector(accountStateSelector, (account) => account.messages);
export const accountAcknowledgementCurrentVIDSelector = createSelector(
    accountStateSelector,
    (account) => account?.currentVid
);

export const currentMessageThreadSelector = createSelector(
    accountMessagesSelector,
    (message) => message?.messageThread
);
