import { createRoutine } from 'redux-saga-routines';

export const accountAddAddressToProfileRoutine = createRoutine('ACCOUNT/ADD_ADDRESS');
export const accountAddPaymentRoutine = createRoutine('ACCOUNT/ADD_PAYMENT');
export const accountFetchHealthConditionsRoutine = createRoutine('ACCOUNT/FETCH_HEALTH_CONDITIONS');
export const accountFetchOrderLinesRoutine = createRoutine('ACCOUNT/FETCH_ORDER_LINES');
export const accountFetchOrderHistoryRoutine = createRoutine('ACCOUNT/FETCH_ORDER_HISTORY');
export const accountFetchProfileRoutine = createRoutine('ACCOUNT/FETCH_PROFILE');
export const accountFetchSecurityQuestionsRoutine = createRoutine('ACCOUNT/FETCH_SECURITY_QUESTIONS');
export const accountFetchWebProfileRoutine = createRoutine('ACCOUNT/FETCH_WEB_PROFILE');
export const accountForgotUsernameRoutine = createRoutine('ACCOUNT/FORGOT_USERNAME');
export const accountGetAllCreditCardsRoutine = createRoutine('ACCOUNT/CREDIT_CARDS');
export const accountGetContactUsMessageRoutine = createRoutine('ACCOUNT/GET_CONTACT_US');
export const accountGetPaymetricDetailsRoutine = createRoutine('ACCOUNT/PAYMETRIC_ACCESS_TOKEN');
export const accountGetTokenizedCardNumberRoutine = createRoutine('ACCOUNT/TOKENIZE_CARD');
export const accountLoginRoutine = createRoutine('ACCOUNT/LOGIN');
export const accountRegisterProfileRoutine = createRoutine('ACCOUNT/REGISTER_PROFILE');
export const accountRegisterRoutine = createRoutine('ACCOUNT/REGISTER');
export const accountRegisterSecurityQuestionsRoutine = createRoutine('ACCOUNT/REGISTER_SEC_QUESTIONS');
export const accountRemoveAddressFromProfileRoutine = createRoutine('ACCOUNT/REMOVE_ADDRESS');
export const accountRemoveCreditCardRoutine = createRoutine('ACCOUNT/REMOVE_CREDIT_CARDS');
export const accountResetPasswordRoutine = createRoutine('ACCOUNT/RESET_PASSWORD');
export const accountSendContactUsMessageRoutine = createRoutine('ACCOUNT/SEND_CONTACT_US');
export const accountSetPrimaryAddressRoutine = createRoutine('ACCOUNT/SET_SET_PRIMARY_ADDRESS');
export const accountSubmitSecurityQuestionAnswerRoutine = createRoutine('ACCOUNT/SUBMIT_SECURITY_QUESTION_ANSWER');
export const accountUpdateAddressToProfileRoutine = createRoutine('ACCOUNT/UPDATE_ADDRESS');
export const accountUpdateAllergiesRoutine = createRoutine('ACCOUNT/ALLERGIES_UPDATE');
export const accountUpdateCreditCardRoutine = createRoutine('ACCOUNT/UPDATE_CREDIT_CARDS');
export const accountUpdateMedicalConditionsRoutine = createRoutine('ACCOUNT/MEDICAL_CONDITIONS_UPDATE');
export const accountUpdateProfileRoutine = createRoutine('ACCOUNT/UPDATE_PROFILE');
export const accountUpdateSecurePasswordRoutine = createRoutine('ACCOUNT/SECURE_PASSWORD_UPDATE');
export const accountFetchNotificationsRoutine = createRoutine('ACCOUNT/FETCH_NOTIFICATIONS');
export const accountUpdateNotificationsRoutine = createRoutine('ACCOUNT/UPDATE_NOTIFICATIONS');
export const accountGetAcknowledgementRoutine = createRoutine('ACCOUNT/GET_ACKNOWLEDGEMENT');
export const accountUpdateAcknowledgementRoutine = createRoutine('ACCOUNT/UPDATE_ACKNOWLEDGEMENT');

// Messages Routines - Ask The Pharm
export const accountFetchAskThePharmMessagesRoutine = createRoutine('ACCOUNT/FETCH_ASK_PHARM');
export const accountFetchAskThePharmComposeMessageRoutine = createRoutine('ACCOUNT/FETCH_ASK_PHARM_COMPOSE_MESSAGE');
export const accountPostAskThePharmComposeMessageRoutine = createRoutine('ACCOUNT/POST_ASK_PHARM_COMPOSE_MESSAGE');
export const accountFetchAskThePharmReplyMessageRoutine = createRoutine('ACCOUNT/FETCH_ASK_PHARM_REPLY_MESSAGE');
export const accountPostAskThePharmReplyMessageRoutine = createRoutine('ACCOUNT/POST_ASK_PHARM_REPLY_MESSAGE');
export const accountGetAskThePharmMessageThreadRoutine = createRoutine('ACCOUNT/GET_ASK_PHARM_MESSAGE_THREAD');
export const accountPostAskThePharmCloseMessageRoutine = createRoutine('ACCOUNT/DELETE_ASK_PHARM_CLOSE_MESSAGE');
// Messages Routines - Contact Us
export const accountFetchContactUsMessagesRoutine = createRoutine('ACCOUNT/FETCH_CONTACT_US_MESSAGES');
export const accountFetchContactUsComposeMessageRoutine = createRoutine('ACCOUNT/FETCH_CONTACT_US_COMPOSE_MESSAGE');
export const accountPostContactUsComposeMessageRoutine = createRoutine('ACCOUNT/POST_CONTACT_US_COMPOSE_MESSAGE');
export const accountFetchContactUsReplyMessageRoutine = createRoutine('ACCOUNT/FETCH_CONTACT_US_REPLY_MESSAGE');
export const accountPostContactUsReplyMessageRoutine = createRoutine('ACCOUNT/POST_CONTACT_US_REPLY_MESSAGE');
export const accountGetContactUsMessageThreadRoutine = createRoutine('ACCOUNT/GET_CONTACT_US_MESSAGE_THREAD');
export const accountPostContactUsCloseMessageRoutine = createRoutine('ACCOUNT/DELETE_CONTACT_US_CLOSE_MESSAGE');
// Messages Routines - Generic
export const accountClearMessageThreadRoutine = createRoutine('ACCOUNT/CLEAR_MESSAGE_THREAD');

export type AccountRoutines = typeof accountRegisterRoutine | typeof accountLoginRoutine;
