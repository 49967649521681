import axiosClient from 'util/axiosClient';

export interface PerscriptionObjectPayload {
    DMEItem: boolean;
    consentDatetime: string;
    consentStatus: string;
    consentStatusDesc: string;
    consentUser: string;
    dawCode: string;
    dawDesc: string;
    directions: string;
    dispensedDrugMaker: string;
    dispensedProductAlias: string | null;
    dispensedProductCompound: string;
    dispensedProductDeaClass: string;
    dispensedProductDeaClassDesc: string;
    dispensedProductDosageForm: string;
    dispensedProductGeneric: boolean;
    dispensedProductId: string;
    dispensedProductMultiSourceCode: string;
    dispensedProductName: string;
    dispensedProductNumber: string;
    dispensedProductStrength: string;
    dispensedProductType: string;
    dispensedProductUnitOfMeasure: string;
    doctorFax: string;
    doctorName: string;
    doctorPhone: string;
    epostDoctorNum: string;
    epostMemberId: string;
    epostOrderNum: string;
    epostPatientNum: string;
    familyId: string;
    fillDaysSupply: string;
    fillNumber: string;
    fillQuantity: string;
    fillsRemaining: string;
    genderCode: string;
    genderDesc: string;
    genericProductIndicator: string;
    itemInWorkflow: boolean;
    itemQueueStatus: string;
    languageDesc: string;
    languageId: string;
    lastFillDate: string;
    nextFillDate: string;
    numRefills: string;
    orderInvoiceNumber: string;
    orderLineQueueStatus: string;
    orderLineStatus: string;
    orderQueueStatus: string;
    orderStatus: 'NONE' | 'CANCELLED' | 'SHIPPED' | 'RETURN PENDING';
    patientCopay: string;
    patientDOB: string;
    patientEmail: string;
    patientFirstName: string;
    patientLastName: string;
    patientMiddleName: string;
    payorPaidAmount: string;
    personCode: string;
    previousRxNumber: string;
    quantityRemaining: string;
    relationshipCode: string;
    relationshipDesc: string;
    rxExpirationDate: string;
    rxFillNumber: string;
    rxFillable: boolean;
    rxNumber: string;
    rxPrecheckDatetime: string;
    rxPrecheckInitials: string;
    rxSeqNum: string;
    rxStatus: string;
    rxStatusNum: string;
    rxTxn: string;
    rxType: string;
    safetyCaps: string;
    specialityItem: boolean;
    writtenDate: string;
    writtenDrugMaker: string;
    writtenProductAlias: string;
    writtenProductCompound: string;
    writtenProductDeaClass: string;
    writtenProductDeaClassDesc: string;
    writtenProductDosageForm: string;
    writtenProductGeneric: boolean;
    writtenProductId: string;
    writtenProductMultiSourceCode: string;
    writtenProductName: string;
    writtenProductNumber: string;
    writtenProductStrength: string;
    writtenProductType: string;
    writtenProductUnitOfMeasure: string;
    writtenQuantity: string;
    rphChecked: boolean;
    webEligibilityStatus: string;
    inOrderCart: boolean;
    prevScriptId: number;
    autoFillStatus: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

/**
 * Anything related to Medicine Cabinet in the system.
 */
export default class MedicineCabinetService {
    static allRxs() {
        return {
            get: (): Promise<any> => {
                return axiosClient
                    .get(`${process.env.GATSBY_API_BASE_URL}/api/Prescriptions/AllRxs`)
                    .then((response) => response.data);
            }
        };
    }
}
