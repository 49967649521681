import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import AddTransferPrescriptionService, {
    TransferPrescriptionPayload
} from 'state/add-transfer-prescription/add-transfer-prescription.services';
import {
    addPrescriptionRoutine,
    transferPrescriptionRoutine,
    addTransferPrescriptionRoutine,
    getFilePrescriptionRoutine,
    photoUploadRoutine,
    getFilePrescriberRoutine,
    getFilePharmacyRoutine
} from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { ProfileObjectPayload } from 'state/account/account.services';
import { accountProfileSelector, accountIsLoggedInSelector } from 'state/account/account.selectors';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackFormSuccess } from 'util/google_optimize/optimize_helper';

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export default function* addTransferPrescriptionSaga() {
    yield takeLatest(addPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(addTransferPrescriptionRoutine(action.payload));
    });

    yield takeLatest(transferPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(transferPrescriptionRoutine(action.payload));
    });

    yield takeLatest(addTransferPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
        const transferObject: TransferPrescriptionPayload = yield select(addTransferPrescriptionSelector);

        const patientId = profileObject.epostPatientNum;
        const isLoggedIn = yield select(accountIsLoggedInSelector);
        const addTransferObject = { patientId: patientId, request: transferObject };
        if (isLoggedIn) {
            yield baseEffectHandler({
                service: AddTransferPrescriptionService.transferRx().post,
                data: addTransferObject,
                *onResponse(data) {
                    yield put(addTransferPrescriptionRoutine.success(data));
                    TrackFormSuccess(transferObject.RxNumber ? 'TransferPrescription' : 'RequestPrescription');
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    yield put(addTransferPrescriptionRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data);
                }
            });
        }
    });
    yield takeLatest(photoUploadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn = yield select(accountIsLoggedInSelector);
        const {
            payload: { imageUrl, onSuccess, onFailure }
        } = action;
        if (isLoggedIn) {
            yield baseEffectHandler<any>({
                service: AddTransferPrescriptionService.photoUpload().post,
                data: imageUrl,
                *onResponse(data) {
                    yield call(delay, 3000);
                    yield baseEffectHandler<any>({
                        service: AddTransferPrescriptionService.getFileFetch().get,
                        data: data.data.body.replace(/"/g, '') + '.json',
                        *onResponse(result) {
                            const { medicationData, pharmacyData, prescriberData } = result;
                            yield put(getFilePrescriptionRoutine.success(medicationData));
                            yield put(getFilePharmacyRoutine.success(pharmacyData));
                            yield put(getFilePrescriberRoutine.success(prescriberData));
                            if (onSuccess) onSuccess(data);
                        },
                        *onError(result) {
                            yield put(getFilePrescriptionRoutine.failure(result));
                        }
                    });
                },
                *onError(data) {
                    yield put(photoUploadRoutine.failure(data));
                    if (onFailure) onFailure();
                }
            });
        }
    });
}
