import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { addTransferPrescriptionState } from './add-transfer-prescription.reducers';

export const AddTransferPrescriptionSelector = (state: RootState) => state.addTransferPrescriptionReducer;

export const addTransferPrescriptionSelector = createSelector(
    AddTransferPrescriptionSelector,
    (addTransferPrescription) => addTransferPrescription.addTransferPrescription
);

export const addTransferPrescriptionStepSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.currentStep
);

export const addTransferPrescriptionTotalStepSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.totalSteps
);

export const addTransferPrescriptionFlowTypeSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.addTransferPrescription.RequestType
);

export const addTransferPrescriptionShowPaymentSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.showPaymentStep
);

export const addTransferPrescriptionShowAddressSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.showAddressStep
);
