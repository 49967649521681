import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { TrackFormSuccess } from 'util/google_optimize/optimize_helper';
import qs from 'qs';

import { AccountState } from 'state/account/account.reducers';
import AccountService, {
    AccountSetupPayload,
    AcknowledgementPayload,
    APIAllergiesPayload,
    APIHealthConditionsPayload,
    APIMedicalConditionsPayload,
    ContactUsComposeMessagePayload,
    ContactUsPayload,
    CreditCardPayload,
    NotificationsPayload,
    PatientRegistrationPayload,
    PaymetricDetailsPayload,
    ProfileObjectAddressPayload,
    ProfileObjectPayload,
    TokenizePaymentCardPayload
} from 'state/account/account.services';
import {
    accountAddAddressToProfileRoutine,
    accountAddPaymentRoutine,
    accountFetchAskThePharmMessagesRoutine,
    accountFetchAskThePharmComposeMessageRoutine,
    accountFetchAskThePharmReplyMessageRoutine,
    accountPostAskThePharmReplyMessageRoutine,
    accountFetchContactUsMessagesRoutine,
    accountFetchContactUsComposeMessageRoutine,
    accountFetchContactUsReplyMessageRoutine,
    accountPostContactUsReplyMessageRoutine,
    accountFetchHealthConditionsRoutine,
    accountFetchNotificationsRoutine,
    accountFetchOrderHistoryRoutine,
    accountFetchOrderLinesRoutine,
    accountFetchProfileRoutine,
    accountFetchSecurityQuestionsRoutine,
    accountFetchWebProfileRoutine,
    accountForgotUsernameRoutine,
    accountGetAllCreditCardsRoutine,
    accountGetContactUsMessageRoutine,
    accountGetPaymetricDetailsRoutine,
    accountGetTokenizedCardNumberRoutine,
    accountLoginRoutine,
    accountRegisterProfileRoutine,
    accountRegisterRoutine,
    accountRemoveAddressFromProfileRoutine,
    accountRemoveCreditCardRoutine,
    accountResetPasswordRoutine,
    accountSendContactUsMessageRoutine,
    accountSetPrimaryAddressRoutine,
    accountSubmitSecurityQuestionAnswerRoutine,
    accountUpdateAddressToProfileRoutine,
    accountUpdateAllergiesRoutine,
    accountUpdateCreditCardRoutine,
    accountUpdateMedicalConditionsRoutine,
    accountUpdateNotificationsRoutine,
    accountUpdateProfileRoutine,
    accountUpdateSecurePasswordRoutine,
    accountPostContactUsComposeMessageRoutine,
    accountPostAskThePharmComposeMessageRoutine,
    accountPostAskThePharmCloseMessageRoutine,
    accountGetAcknowledgementRoutine,
    accountUpdateAcknowledgementRoutine,
    accountGetAskThePharmMessageThreadRoutine,
    accountGetContactUsMessageThreadRoutine,
    accountPostContactUsCloseMessageRoutine,
    accountClearMessageThreadRoutine
} from 'state/account/account.routines';

import storageHelper from 'util/storageHelper';
import { Login, LoginRequest } from 'types/login';
import { baseEffectHandler } from 'util/sagas/sagas';
import { navigate } from 'gatsby';
import {
    accountCreditCardsSelector,
    accountIsLoggedInSelector,
    accountProfileAddressesSelector,
    accountProfileSelector,
    accountRegistationSelector,
    paymetricDetailsSelector,
    accountAcknowledgementCurrentVIDSelector
} from './account.selectors';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import { Allergies, MedicalConditions } from 'types/health-profile';
import produce from 'immer';
import { SecureChangePasswordSubmission } from 'types/change-password';
import {
    FetchSecurityQuestionsRequest,
    ResetPasswordRequest,
    SubmitSecurityQuestionAnswerRequest
} from 'types/forgot-password';
import { RegistrationFormValues } from 'components/registration-form/registration-form.component';
import { FormikHelpers } from 'formik';
import { ProfileFormValues } from 'types/profile';
import {
    OrderHistoryRequestPayload,
    OrderHistoryResponsePayload,
    OrderLinesRequestPayload,
    OrderLinesResponsePayload
} from 'types/order';
import { ForgotUsernameRequestPayload, ForgotUsernameResponsePayload } from 'types/forgot-username';
import { cartUpdatePaymentRoutine } from 'state/cart/cart.routines';
import { cartSelector } from 'state/cart/cart.selectors';
import { CartObjectPayload } from 'state/cart/cart.services';

const reduceHealthProfileResponse = <P extends APIHealthConditionsPayload, T extends any>(response: P): T => {
    const { Ampicillin, messageStatus, messageErrorText, messageText, ...reduced } = response;
    return { ...reduced } as T;
};

function* getAccountHealthConditions(): any {
    try {
        const conditionsResponse = yield call(AccountService.medicalConditions().getAll);
        const allergiesResponse = yield call(AccountService.allergies().getAll);

        if (conditionsResponse.error || allergiesResponse.error) {
            throw conditionsResponse.error ?? allergiesResponse.error;
        } else {
            yield put(
                accountFetchHealthConditionsRoutine.success({
                    medicalConditions: reduceHealthProfileResponse<APIMedicalConditionsPayload, MedicalConditions>(
                        conditionsResponse
                    ),
                    allergies: reduceHealthProfileResponse<APIAllergiesPayload, Allergies>(allergiesResponse)
                })
            );
        }
    } catch {
        yield put(accountFetchHealthConditionsRoutine.failure());
    }
}

export default function* accountSaga() {
    yield takeLatest(accountFetchHealthConditionsRoutine.TRIGGER, getAccountHealthConditions);
    yield takeLatest(accountFetchOrderHistoryRoutine.TRIGGER, (action: PayloadAction<OrderHistoryRequestPayload>) =>
        baseEffectHandler<OrderHistoryResponsePayload>({
            service: AccountService.orderHistory().get,
            data: action.payload,
            *onResponse(response) {
                yield put(accountFetchOrderHistoryRoutine.success({ orders: response }));
            },
            *onError() {
                yield put(accountFetchOrderHistoryRoutine.failure());
            }
        })
    );
    yield takeLatest(accountFetchOrderLinesRoutine.TRIGGER, (action: PayloadAction<OrderLinesRequestPayload>) =>
        baseEffectHandler<OrderLinesResponsePayload>({
            service: AccountService.orderLines().get,
            data: action.payload,
            *onResponse(response) {
                yield put(
                    accountFetchOrderLinesRoutine.success({
                        epostOrderNum: action.payload.epostOrderNum,
                        orderLines: response
                    })
                );
            },
            *onError() {
                yield put(accountFetchOrderLinesRoutine.failure());
            }
        })
    );
    yield takeLatest(accountUpdateMedicalConditionsRoutine.TRIGGER, (action: PayloadAction<any>) =>
        baseEffectHandler<MedicalConditions>({
            service: AccountService.medicalConditions().post,
            data: action.payload,
            *onResponse() {
                yield put(accountFetchHealthConditionsRoutine.trigger());
                yield put(accountUpdateMedicalConditionsRoutine.success(action.payload));
                TrackFormSuccess('HealthConditions');
            },
            *onError() {
                yield put(accountUpdateMedicalConditionsRoutine.failure());
            }
        })
    );
    yield takeLatest(accountUpdateAllergiesRoutine.TRIGGER, (action: PayloadAction<any>) =>
        baseEffectHandler<Allergies>({
            service: AccountService.allergies().post,
            data: action.payload,
            *onResponse() {
                yield put(accountFetchHealthConditionsRoutine.trigger());
                yield put(accountUpdateAllergiesRoutine.success(action.payload));
                TrackFormSuccess('Allergies');
            },
            *onError() {
                yield put(accountUpdateAllergiesRoutine.failure());
            }
        })
    );
    yield takeLatest(accountLoginRoutine.TRIGGER, (action: PayloadAction<LoginRequest>) =>
        baseEffectHandler<Login>({
            service: AccountService.login().post,
            data: qs.stringify({
                username: action.payload.login.email,
                password: action.payload.login.password,
                grant_type: 'password'
            }),
            *onResponse(response: any) {
                const { onSuccess, onFailure } = action.payload;
                //  the API response contains an embedded error object that we need to examine and return to the UI for display
                if (response.data && response.data.error) {
                    if (onFailure) {
                        yield put(accountLoginRoutine.failure());
                        onFailure({ error: response.data.error, description: response.data.error_description });
                    }
                } else {
                    yield put(accountLoginRoutine.success(response));
                    if (window && window.dataLayer) {
                        window.dataLayer.push({ event: 'login', loginStatus: 'logged in' });
                    }
                    // Removing these two API calls, since we are doing a hard pageload
                    // after logging in due to Robin Chatbot Session restrictions.
                    // Keeping here in the hope that we can pass session info to the
                    // chatbot without doing a hard pageload.
                    //
                    //yield put(accountFetchProfileRoutine.trigger());
                    //yield put(getCartRoutine.trigger());
                    if (onSuccess) onSuccess();
                }
            },
            *onError(err: string) {
                const { onFailure } = action.payload;
                yield put(accountLoginRoutine.failure());
                if (onFailure) onFailure({ error: err });
            }
        })
    );
    yield takeLatest(
        accountRegisterRoutine.TRIGGER,
        (
            action: PayloadAction<{
                formValues: Partial<RegistrationFormValues>;
                formHelpers: FormikHelpers<Partial<RegistrationFormValues>>;
                errorMessage: String | undefined;
            }>
        ) =>
            baseEffectHandler<RegistrationFormValues>({
                service: AccountService.register().get,
                data: action.payload.formValues,
                *onResponse(data: PatientRegistrationPayload) {
                    yield put(
                        accountRegisterRoutine.success({
                            patientRegistrationPayload: data,
                            formValues: action.payload.formValues
                        })
                    );
                    TrackFormSuccess('Registration');
                    navigate('/get-started/profile-security-questions');
                },
                *onError(err: any) {
                    action.payload.formHelpers.setSubmitting(false);
                    action.payload.formHelpers.setStatus(
                        action.payload.errorMessage ? action.payload.errorMessage : err.response.data.SystemMessage
                    );
                    yield put(accountRegisterRoutine.failure(err));
                }
            })
    );

    yield takeLatest(
        accountRegisterProfileRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                formValues: Partial<ProfileFormValues>;
                formHelpers: FormikHelpers<Partial<ProfileFormValues>>;
                errorMessage: String | undefined;
            }>
        ) {
            const registrationData: AccountState['registration'] = yield select(accountRegistationSelector);

            yield baseEffectHandler({
                service: AccountService.accountSetup().post,
                data: registrationData.patientRegistration,
                *onResponse({ data }: { data: AccountSetupPayload }) {
                    if (
                        registrationData.patientRegistration !== undefined &&
                        registrationData.patientRegistrationSecurityQuestions !== undefined
                    ) {
                        data.Email = (action.payload.formValues as ProfileFormValues).email;
                        data.UserName = (action.payload.formValues as ProfileFormValues).email;
                        data.Password = (action.payload.formValues as ProfileFormValues).password;
                        data.PhoneNumber = (action.payload.formValues as ProfileFormValues).phoneNumber;
                        data.PasswordQuestion1 = registrationData.patientRegistrationSecurityQuestions.question1;
                        data.PasswordQuestion2 = registrationData.patientRegistrationSecurityQuestions.question2;
                        data.PasswordQuestion3 = registrationData.patientRegistrationSecurityQuestions.question3;
                        data.PasswordAnswer1 = registrationData.patientRegistrationSecurityQuestions.answer1;
                        data.PasswordAnswer2 = registrationData.patientRegistrationSecurityQuestions.answer2;
                        data.PasswordAnswer3 = registrationData.patientRegistrationSecurityQuestions.answer3;
                    }
                    yield baseEffectHandler({
                        service: AccountService.accountFinalize().post,
                        data: data,
                        *onResponse({ data }: { data: PatientRegistrationPayload }) {
                            //TODO need to resolve the tos accept here

                            yield put(accountRegisterProfileRoutine.success({}));
                            TrackFormSuccess('RegisterProfile');
                            yield put(
                                accountLoginRoutine.trigger({
                                    login: {
                                        email: (action.payload.formValues as ProfileFormValues).email,
                                        password: (action.payload.formValues as ProfileFormValues).password
                                    },
                                    onSuccess: () => {
                                        navigate('/secure/profile');
                                    }
                                })
                            );
                        },
                        *onError(data: any) {
                            action.payload.formHelpers.setSubmitting(false);
                            action.payload.formHelpers.setStatus(
                                action.payload.errorMessage
                                    ? action.payload.errorMessage
                                    : 'There was an error finalizing your registration'
                            );
                            yield put(accountRegisterProfileRoutine.failure({}));
                        }
                    });
                },
                *onError(data: Login) {
                    action.payload.formHelpers.setSubmitting(false);
                    action.payload.formHelpers.setStatus(
                        action.payload.errorMessage
                            ? action.payload.errorMessage
                            : 'There was an error finalizing your registration'
                    );
                    yield put(accountRegisterProfileRoutine.failure({}));
                }
            });
        }
    );

    yield takeLatest(accountFetchProfileRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.fetchProfile().get,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data: any) {
                yield put(accountFetchProfileRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountFetchProfileRoutine.failure(data));
            }
        });
    });
    yield takeLatest(
        accountAddAddressToProfileRoutine.TRIGGER,
        function* (action: PayloadAction<ProfileObjectAddressPayload>) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const profileAddresses: ProfileObjectAddressPayload[] = yield select(accountProfileAddressesSelector);
            const defaultBill = !profileAddresses || profileAddresses.length === 0 ? true : false;
            const defaultShip = !profileAddresses || profileAddresses.length === 0 ? true : false;
            const profileObjectPayload: ProfileObjectPayload = {
                ...profileObject,
                addresses: [
                    ...profileObject.addresses,
                    {
                        ...action.payload,
                        defaultBill,
                        defaultShip,
                        epostPatientNum: profileObject.epostPatientNum
                    }
                ]
            };

            yield baseEffectHandler({
                service: AccountService.fetchProfile().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: profileObjectPayload,
                *onResponse(data: any) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess('AddAddressToProfile');
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data: Login) {
                    yield put(accountUpdateProfileRoutine.failure(profileObject));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );
    yield takeLatest(
        accountUpdateAddressToProfileRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                index: number;
                address: ProfileObjectAddressPayload;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const profileObjectPayload: ProfileObjectPayload = produce(profileObject, (draftProfileObject) => {
                draftProfileObject.addresses[action.payload.index] = {
                    ...draftProfileObject.addresses[action.payload.index],
                    addressTypeDesc: action.payload.address.addressTypeDesc,
                    addressTypeNum: action.payload.address.addressTypeNum,
                    address1: action.payload.address.address1,
                    address2: action.payload.address.address2,
                    city: action.payload.address.city,
                    state: action.payload.address.state,
                    zipcode: action.payload.address.zipcode,
                    zipcodeFour: action.payload.address.zipcodeFour
                };
            });

            yield baseEffectHandler<any>({
                service: AccountService.fetchProfile().post,
                data: profileObjectPayload,
                *onResponse(data: any) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess('UpdateAddressToProfile');
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data: Login) {
                    alert('Failure to update address to profile data');
                    yield put(accountFetchProfileRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(
        accountRemoveAddressFromProfileRoutine.TRIGGER,
        function* (action: PayloadAction<AddressCardProps>) {
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);

            const addressToRemove = {
                ...profileObject.addresses.find(
                    (address) =>
                        address.address1 === action.payload.address1 &&
                        address.address2 === action.payload.address2 &&
                        address.city === action.payload.city &&
                        address.state === action.payload.state &&
                        address.zipcode === action.payload.zipcode.toString()
                )
            };
            if (addressToRemove) {
                addressToRemove.active = false;
            }
            const profileObjectPayload: ProfileObjectPayload = {
                ...profileObject,
                addresses: [
                    ...profileObject.addresses.filter(
                        (address) =>
                            address.address1 !== action.payload.address1 &&
                            address.address2 !== action.payload.address2 &&
                            address.city !== action.payload.city &&
                            address.state !== action.payload.state &&
                            address.zipcode !== action.payload.zipcode.toString()
                    )
                ]
            };
            if (addressToRemove) {
                profileObjectPayload.addresses.push(addressToRemove as any);
            }

            yield baseEffectHandler<ProfileObjectPayload>({
                service: AccountService.fetchProfile().post,
                data: profileObjectPayload,
                *onResponse(data: ProfileObjectPayload) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess('RemoveAddressFromProfile');
                },
                *onError(data: Login) {
                    alert('Failure to update address form profile data');
                    yield put(accountFetchProfileRoutine.failure(data));
                }
            });
        }
    );

    yield takeLatest(accountSetPrimaryAddressRoutine.TRIGGER, function* (action: PayloadAction<AddressCardProps>) {
        const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
        const profileObjectPayload: ProfileObjectPayload = produce(profileObject, (draftProfileObject) => {
            const newPrimaryAddressIndex = draftProfileObject.addresses.findIndex(
                (address) =>
                    address.address1 === action.payload.address1 &&
                    address.address2 === action.payload.address2 &&
                    address.city === action.payload.city &&
                    address.state === action.payload.state &&
                    address.zipcode === action.payload.zipcode.toString()
            );

            const updatedAddressPayload = draftProfileObject.addresses
                .map((address, index) => {
                    const isNewPrimaryAddress = index === newPrimaryAddressIndex;
                    return {
                        ...address,
                        defaultShip: isNewPrimaryAddress,
                        currentShipping: isNewPrimaryAddress,
                        defaultBill: isNewPrimaryAddress
                    };
                })
                .sort((a, b) => Number(b.defaultShip) - Number(a.defaultShip));
            draftProfileObject.addresses = [...updatedAddressPayload];
        });

        yield baseEffectHandler<ProfileObjectPayload>({
            service: AccountService.fetchProfile().post,
            data: profileObjectPayload,
            *onResponse(data: ProfileObjectPayload) {
                yield put(accountUpdateProfileRoutine.success(data));
                TrackFormSuccess('SetPrimaryAddress');
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data: Login) {
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure();
                } else {
                    alert('Failure to update profile primary address');
                }
                yield put(accountFetchProfileRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateProfileRoutine.TRIGGER, (action: PayloadAction<ProfileObjectPayload>) =>
        baseEffectHandler<ProfileObjectPayload>({
            service: AccountService.fetchProfile().post,
            data: action.payload,
            *onResponse(data: ProfileObjectPayload) {
                yield put(accountUpdateProfileRoutine.success(data));
                TrackFormSuccess('UpdateProfile');
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data: Login) {
                yield put(accountFetchProfileRoutine.failure(data));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure();
                } else {
                    alert('Failure to update profile data');
                }
            }
        })
    );

    yield takeLatest(accountFetchWebProfileRoutine.TRIGGER, () =>
        baseEffectHandler({
            service: AccountService.fetchWebProfile().get,
            *onResponse(data) {
                yield put(accountFetchWebProfileRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountFetchWebProfileRoutine.failure(data));
            }
        })
    );

    yield takeLatest(
        accountUpdateSecurePasswordRoutine.TRIGGER,
        (action: PayloadAction<SecureChangePasswordSubmission>) =>
            baseEffectHandler<SecureChangePasswordSubmission>({
                service: AccountService.changePassword().update,
                data: action.payload,
                *onResponse({ data }: any) {
                    if (data.length === 0) {
                        // Done this way because the API will return a "success" response even if the request is a failure
                        yield put(accountUpdateSecurePasswordRoutine.success(true));
                        TrackFormSuccess('UpdateSecurePassword');
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    } else {
                        yield put(accountUpdateSecurePasswordRoutine.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure(data);
                    }
                }
            })
    );

    yield takeLatest(
        accountFetchSecurityQuestionsRoutine.TRIGGER,
        (action: PayloadAction<FetchSecurityQuestionsRequest>) =>
            baseEffectHandler({
                service: AccountService.fetchSecurityQuestions().get,
                data: action.payload.username,
                *onResponse(response) {
                    const { uid } = response;
                    if (!uid) {
                        yield put(accountFetchSecurityQuestionsRoutine.failure());
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    } else {
                        yield put(accountFetchSecurityQuestionsRoutine.success(response));
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    }
                },
                *onError(err) {
                    yield put(accountFetchSecurityQuestionsRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            })
    );

    yield takeLatest(
        accountSubmitSecurityQuestionAnswerRoutine.TRIGGER,
        (action: PayloadAction<SubmitSecurityQuestionAnswerRequest>) =>
            baseEffectHandler({
                service: AccountService.submitSecurityQuestionAnswer().post,
                data: action.payload.accountSecurity,
                *onResponse(response) {
                    //  if there is any response it's a failure
                    if (response && response.length > 0) {
                        yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    } else {
                        yield put(accountSubmitSecurityQuestionAnswerRoutine.success());
                        TrackFormSuccess('SubmitSecurityQuestionAnswer');
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    }
                },
                *onError(err) {
                    yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            })
    );

    yield takeLatest(accountResetPasswordRoutine.TRIGGER, (action: PayloadAction<ResetPasswordRequest>) =>
        baseEffectHandler({
            service: AccountService.resetPasswordWithToken().post,
            data: action.payload.accountSecurity,
            *onResponse(response) {
                //  if there is any response it's a failure
                if (response && response.length > 0) {
                    yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                } else {
                    yield put(accountSubmitSecurityQuestionAnswerRoutine.success());
                    TrackFormSuccess('ResetPassword');
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                }
            },
            *onError(err) {
                yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        })
    );

    yield takeLatest(accountGetAllCreditCardsRoutine.TRIGGER, function* () {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.paymentMethods().getAll,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const paymentInfo = [...data];
                const sortedPaymentData = paymentInfo.sort((a, b) =>
                    a.defaultCard === b.defaultCard ? 0 : a.defaultCard ? -1 : 1
                );
                yield put(accountGetAllCreditCardsRoutine.success(sortedPaymentData));
            },
            *onError(data) {
                // Done this way because the API is throwing an error (now) when a user has no cards on file
                yield put(accountGetAllCreditCardsRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountAddPaymentRoutine.TRIGGER, function* (action: PayloadAction<CreditCardPayload>) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);
        const cartObject: CartObjectPayload = yield select(cartSelector);
        let newCard = action.payload;
        if (!creditCards || creditCards.length === 0) {
            newCard.defaultCard = true;
        }
        yield baseEffectHandler<CreditCardPayload>({
            service: AccountService.paymentMethods().post,
            data: newCard,
            *onResponse(data: CreditCardPayload) {
                const updatedCreditCardPayload: CreditCardPayload[] = creditCards ? [...creditCards, data] : [data];
                yield put(accountAddPaymentRoutine.success(updatedCreditCardPayload));
                yield put(accountGetAllCreditCardsRoutine.trigger());
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                if (
                    cartObject !== undefined &&
                    cartObject.orderBillShip.paymentCardSeqNum === null &&
                    updatedCreditCardPayload.length === 1
                ) {
                    const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                        draftCartObject.orderBillShip.paymentCardSeqNum = data.cardSeqNum;
                    });
                    yield put(cartUpdatePaymentRoutine.trigger({ ...cartObjectPayload.orderBillShip }));
                }
            },
            *onError(data: any) {
                yield put(accountAddPaymentRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountRemoveCreditCardRoutine.TRIGGER, function* (action: PayloadAction<CreditCardPayload>) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);

        const paymentToRemove = {
            ...creditCards.find(
                (card) =>
                    card.cardSeqNum === action.payload.cardSeqNum &&
                    card.secureCardNumber === action.payload.secureCardNumber &&
                    card.cardMonthDesc === action.payload.cardMonthDesc &&
                    card.cardYear === action.payload.cardYear
            )
        };
        if (paymentToRemove) {
            paymentToRemove.cardActive = false;
        }

        yield baseEffectHandler<CreditCardPayload>({
            service: AccountService.paymentMethods().post,
            data: paymentToRemove,
            *onResponse(data: CreditCardPayload) {
                const updateIndex = creditCards.indexOf(action.payload);
                const updatedPayload = creditCards.map((card, index) => {
                    return index !== updateIndex ? card : data;
                });
                yield put(accountRemoveCreditCardRoutine.success(updatedPayload));
                TrackFormSuccess('RemoveCreditCard');
            },
            *onError(data: Login) {
                alert('Failure to remove payment');
                yield put(accountRemoveCreditCardRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateCreditCardRoutine.TRIGGER, function* (action: PayloadAction<CreditCardPayload>) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);

        const paymentToUpdate = {
            ...creditCards.find(
                (card) =>
                    card.cardSeqNum === action.payload.cardSeqNum &&
                    card.secureCardNumber === action.payload.secureCardNumber &&
                    card.cardMonthDesc === action.payload.cardMonthDesc &&
                    card.cardYear === action.payload.cardYear
            )
        };
        if (paymentToUpdate) {
            paymentToUpdate.defaultCard = true;
        }

        yield baseEffectHandler<CreditCardPayload>({
            service: AccountService.paymentMethods().post,
            data: paymentToUpdate,
            *onResponse(data: CreditCardPayload) {
                const updateIndex = creditCards.indexOf(action.payload);
                const updatedPayload = creditCards.map((card, index) => {
                    let newCard = Object.assign({}, card);
                    if (index !== updateIndex) {
                        newCard.defaultCard = false;
                    } else {
                        newCard = data;
                    }
                    return newCard;
                });
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountUpdateCreditCardRoutine.success(updatedPayload));
                TrackFormSuccess('UpdateCreditCard');
            },
            *onError(data: Login) {
                alert('Failure to update payment');
                yield put(accountRemoveCreditCardRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountGetPaymetricDetailsRoutine.TRIGGER, () =>
        baseEffectHandler({
            service: AccountService.paymentMethods().getAccessID,
            *onResponse(data) {
                yield put(accountGetPaymetricDetailsRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountGetPaymetricDetailsRoutine.failure(data));
            }
        })
    );

    yield takeLatest(accountGetTokenizedCardNumberRoutine.TRIGGER, () =>
        baseEffectHandler({
            service: AccountService.paymentMethods().getTokenizedCardNumber,
            *onResponse(data) {
                yield put(accountGetTokenizedCardNumberRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountGetTokenizedCardNumberRoutine.failure(data));
            }
        })
    );

    yield takeLatest(
        accountGetTokenizedCardNumberRoutine.TRIGGER,
        function* (action: PayloadAction<TokenizePaymentCardPayload>) {
            const paymetricDetails: PaymetricDetailsPayload = yield select(paymetricDetailsSelector);
            const accessToken = paymetricDetails?.AccessToken;

            yield baseEffectHandler({
                service: AccountService.paymentMethods().getTokenizedCardNumber,
                data: accessToken,
                *onResponse(data) {
                    if (data.Body.length !== 0 && data.Body !== null) {
                        // Done this way because the API will return a "success" response even if the request is a failure
                        yield put(accountGetTokenizedCardNumberRoutine.success(data));
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess(data.Body);
                    } else {
                        yield put(accountGetTokenizedCardNumberRoutine.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure(data);
                    }
                }
            });
        }
    );

    yield takeLatest(accountGetContactUsMessageRoutine.TRIGGER, function* (action: PayloadAction<Boolean>) {
        yield baseEffectHandler<any>({
            service: AccountService.contactUs().get,
            data: action.payload,
            *onResponse(data: ContactUsPayload) {
                yield put(accountGetContactUsMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountGetContactUsMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountSendContactUsMessageRoutine.TRIGGER, function* (action: PayloadAction<ContactUsPayload>) {
        yield baseEffectHandler<ContactUsPayload>({
            service: AccountService.contactUs().post,
            data: action.payload,
            *onResponse(data: ContactUsPayload) {
                yield put(accountSendContactUsMessageRoutine.success(data));
                TrackFormSuccess('SendContactUsMessage');
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data: any) {
                yield put(accountSendContactUsMessageRoutine.failure(data));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(
        accountForgotUsernameRoutine.TRIGGER,
        function* (action: PayloadAction<ForgotUsernameRequestPayload>) {
            yield baseEffectHandler<ForgotUsernameRequestPayload>({
                service: AccountService.forgotUsername().get,
                data: action.payload,
                *onResponse(data: ForgotUsernameResponsePayload) {
                    yield put(accountForgotUsernameRoutine.success());
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data: any) {
                    yield put(accountForgotUsernameRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(accountFetchNotificationsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
        const profilePhoneNumber = `${profileObject?.phones[0].phoneAreaCode}${profileObject?.phones[0].phoneNumber}`;
        const profileEmailAddress = profileObject?.patientEmailAddress;
        yield baseEffectHandler<NotificationsPayload>({
            service: AccountService.notifications().get,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data: NotificationsPayload) {
                const notificationPhoneNumbers = [
                    'RefillReminderTextNumber',
                    'OrderShippedTextNumber',
                    'NewScriptTextNumber',
                    'NewScriptPhoneNumber',
                    'RefillReminderPhoneNumber',
                    'OrderShippedPhoneNumber'
                ];
                const hasNotificationNumbers = notificationPhoneNumbers
                    .map((number) => data[number])
                    .every((number) => number === profilePhoneNumber);

                const notificationEmailAddresses = [
                    'NewScriptEmailAddress',
                    'RefillReminderEmailAddress',
                    'OrderShippedEmailAddress'
                ];
                const hasNotificationEmailAddresses = notificationEmailAddresses
                    .map((email) => data[email])
                    .every((email) => email === profileEmailAddress);
                if (hasNotificationNumbers && hasNotificationEmailAddresses) {
                    yield put(accountFetchNotificationsRoutine.success(data));
                } else {
                    const updatedPayload = {
                        ...data,
                        RefillReminderTextNumber: profilePhoneNumber,
                        OrderShippedTextNumber: profilePhoneNumber,
                        NewScriptTextNumber: profilePhoneNumber,
                        NewScriptPhoneNumber: profilePhoneNumber,
                        RefillReminderPhoneNumber: profilePhoneNumber,
                        OrderShippedPhoneNumber: profilePhoneNumber,
                        NewScriptEmailAddress: profileEmailAddress,
                        RefillReminderEmailAddress: profileEmailAddress,
                        OrderShippedEmailAddress: profileEmailAddress
                    };
                    yield put(accountFetchNotificationsRoutine.success(updatedPayload));
                }
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(data);
            },
            *onError(data: any) {
                yield put(accountFetchNotificationsRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateNotificationsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler<NotificationsPayload>({
            service: AccountService.notifications().post,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                // Done this way because the API returns a "successful" response even if the API fails
                if (data.messageErrorText === null) {
                    yield put(accountUpdateNotificationsRoutine.success(action.payload));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(action.payload);
                } else {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            },
            *onError(data: any) {
                yield put(accountUpdateNotificationsRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountFetchAskThePharmMessagesRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.askThePharm().getAll,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchAskThePharmMessagesRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchAskThePharmMessagesRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountFetchAskThePharmComposeMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.askThePharm().getCompose,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchAskThePharmComposeMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchAskThePharmComposeMessageRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountPostAskThePharmComposeMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.askThePharm().postMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountPostAskThePharmComposeMessageRoutine.success(data));
            },
            *onError(data) {
                console.error(data);
                yield put(accountPostAskThePharmComposeMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountFetchAskThePharmReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.askThePharm().getReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchAskThePharmReplyMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchAskThePharmReplyMessageRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountPostAskThePharmReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.askThePharm().postReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountPostAskThePharmReplyMessageRoutine.success(data));
            },
            *onError(data) {
                console.error(data);
                yield put(accountPostAskThePharmReplyMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountGetAskThePharmMessageThreadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.askThePharm().getMessageThread,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                const threadData = {
                    replies: data,
                    subject: action.payload.Subject,
                    messageSource: action.payload.messageSource,
                    threadStatus: action.payload.Action
                };
                yield put(accountGetAskThePharmMessageThreadRoutine.success(threadData));
            },
            *onError(data) {
                console.error(data);
                yield put(accountPostContactUsComposeMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountPostAskThePharmCloseMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.askThePharm().closeMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountPostAskThePharmCloseMessageRoutine.success(data));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                yield put(accountPostAskThePharmCloseMessageRoutine.failure([]));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(accountFetchContactUsMessagesRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.contactUsMessages().getAll,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchContactUsMessagesRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchContactUsMessagesRoutine.failure([]));
            }
        });
    });

    yield takeLatest(
        accountFetchContactUsComposeMessageRoutine.TRIGGER,
        function* (action: PayloadAction<ContactUsComposeMessagePayload>) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

            yield baseEffectHandler({
                service: AccountService.contactUsMessages().getCompose,
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data) {
                    yield put(accountFetchContactUsComposeMessageRoutine.success(data));
                },
                *onError(data) {
                    yield put(accountFetchContactUsComposeMessageRoutine.failure([]));
                }
            });
        }
    );

    yield takeLatest(accountPostContactUsComposeMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.contactUsMessages().postMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountPostContactUsComposeMessageRoutine.success(data));
            },
            *onError(data) {
                console.error(data);
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(accountPostContactUsComposeMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountGetContactUsMessageThreadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.contactUsMessages().getMessageThread,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                const threadData = {
                    replies: data,
                    subject: action.payload.Subject,
                    messageSource: action.payload.messageSource,
                    threadStatus: action.payload.Action
                };
                yield put(accountGetContactUsMessageThreadRoutine.success(threadData));
            },
            *onError(data) {
                console.error(data);
                yield put(accountGetContactUsMessageThreadRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountFetchContactUsReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.contactUsMessages().getReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchContactUsReplyMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchContactUsReplyMessageRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountPostContactUsReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.contactUsMessages().postReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountPostContactUsReplyMessageRoutine.success(data));
            },
            *onError(data) {
                console.error(data);
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(accountPostContactUsReplyMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountPostContactUsCloseMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.contactUsMessages().closeMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountPostContactUsCloseMessageRoutine.success(data));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                yield put(accountPostContactUsCloseMessageRoutine.failure([]));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(accountClearMessageThreadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(accountClearMessageThreadRoutine.success({}));
    });

    yield takeLatest(accountGetAcknowledgementRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        let currentVID: number | undefined = yield select(accountAcknowledgementCurrentVIDSelector);
        let { patientId, clientIP } = action.payload;
        const hasAcknowledged = storageHelper.local.getAcknowledged();
        if (!hasAcknowledged) {
            if (!currentVID) {
                // Since we are reloading the site after logic to get Session variables to Robin,
                // we have to set a localStorage variable for the tosVID
                currentVID = Number(storageHelper.local.getTosVID());
            }
            if (!clientIP) {
                clientIP = storageHelper.local.getClientIP();
            }

            const termsOfServiceAcknowledgementObject = {
                EPostPatientNum: patientId,
                version: currentVID,
                type: 'Terms of Use',
                timestamp: new Date().toISOString(),
                source: 'Birdi Website',
                sourceIp: clientIP
            };

            yield baseEffectHandler<AcknowledgementPayload>({
                service: AccountService.acknowledgement().getAll,
                data: patientId,
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data) {
                    if (data[0] === '' || currentVID > data[0].Acknowledgements[0].Version) {
                        // if current version is out of date run both
                        yield baseEffectHandler<AcknowledgementPayload>({
                            service: AccountService.acknowledgement().postAll,
                            data: {
                                patientId: patientId,
                                termsOfServiceAcknowledgementObject: termsOfServiceAcknowledgementObject
                            },
                            isAuthenticatedService: true,
                            isLoggedIn,
                            *onResponse(data) {
                                storageHelper.local.setAcknowledged(true);
                                yield put(accountUpdateAcknowledgementRoutine.success(data));
                            },
                            *onError(data: any) {
                                yield put(accountUpdateAcknowledgementRoutine.failure(data));
                            }
                        });
                    } else if (!data[1].HIPAAA) {
                        // if HIPAA is false only run HIPAA
                        yield baseEffectHandler<AcknowledgementPayload>({
                            service: AccountService.acknowledgement().postHipaa,
                            data: {
                                patientId: patientId
                            },
                            isAuthenticatedService: true,
                            isLoggedIn,
                            *onResponse(data) {
                                storageHelper.local.setAcknowledged(true);
                                yield put(accountUpdateAcknowledgementRoutine.success(data));
                            },
                            *onError(data: any) {
                                yield put(accountUpdateAcknowledgementRoutine.failure(data));
                            }
                        });
                    } else {
                        // else run like nothing happened
                        storageHelper.local.setAcknowledged(true);
                        yield put(accountGetAcknowledgementRoutine.success(data));
                    }
                },
                *onError(data: any) {
                    yield put(accountGetAcknowledgementRoutine.failure(data));
                }
            });
        } else {
            yield put(accountGetAcknowledgementRoutine.success());
        }
    });
}
