import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PerscriptionObjectPayload } from './medicine-cabinet.services';
import { produce } from 'immer';
import { medicineCabinetGetAllPrescriptions } from './medicine-cabinet.routines';

export interface MedicineCabinetState {
    error?: string;
    isBusy?: boolean;
    currentPerscriptions?: PerscriptionObjectPayload[];
    showNewPrescriptionModal: {
        show: boolean;
        prescriptions: string[];
    };
}

export const initialState: MedicineCabinetState = {
    currentPerscriptions: [],
    showNewPrescriptionModal: {
        show: false,
        prescriptions: []
    }
};

const medicineCabinetSlice = createSlice({
    name: 'medicine-cabinet',
    initialState,
    reducers: {
        setMedicineCabinet(state: MedicineCabinetState, action) {
            state.error = undefined;
            state.isBusy = false;
        },
        showNewPrescriptionModal(
            state: MedicineCabinetState,
            { payload }: PayloadAction<MedicineCabinetState['showNewPrescriptionModal']>
        ) {
            state.showNewPrescriptionModal = payload;
        },
        closeNewPrescriptionModal(state: MedicineCabinetState) {
            state.showNewPrescriptionModal.show = false;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Prescriptions Reducers
         */
        addCase(
            medicineCabinetGetAllPrescriptions.SUCCESS,
            (state, { payload }: PayloadAction<PerscriptionObjectPayload[]>) =>
                produce(state, (draftState) => {
                    draftState.currentPerscriptions = payload;
                })
        );
    }
});

export const { closeNewPrescriptionModal, setMedicineCabinet, showNewPrescriptionModal } = medicineCabinetSlice.actions;

export default medicineCabinetSlice.reducer;
