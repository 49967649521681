import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import accountSaga from 'state/account/account.sagas';
import medicineCabinetSaga from 'state/medicine-cabinet/medicine-cabinet.sagas';
import uspsSaga from 'state/usps/usps.sagas';
import addTransferPrescriptionSaga from 'state/add-transfer-prescription/add-transfer-prescription.sagas';
import cartSaga from 'state/cart/cart.sagas';

import accountReducer from 'state/account/account.reducers';
import birdiModalReducer from 'state/birdi-modal/birdi-modal.reducers';
import medicineCabinetReducer from 'state/medicine-cabinet/medicine-cabinet.reducers';
import cartReducer from 'state/cart/cart.reducers';
import uspsReducer from 'state/usps/usps.reducers';
import addTransferPrescriptionReducer from 'state/add-transfer-prescription/add-transfer-prescription.reducers';

const sagaMiddleware = createSagaMiddleware();

const sagas = [accountSaga, medicineCabinetSaga, uspsSaga, addTransferPrescriptionSaga, cartSaga];
const reducers = combineReducers({
    accountReducer,
    birdiModalReducer,
    medicineCabinetReducer,
    uspsReducer,
    addTransferPrescriptionReducer,
    cartReducer
});

const createStore = () => {
    const store = configureStore({
        reducer: reducers,
        middleware: [sagaMiddleware]
    });
    sagas.map(sagaMiddleware.run);
    return store;
};

export type RootState = ReturnType<typeof reducers>;
export default createStore;
